import * as React from 'react';
import {
  Breadcrumbs,
  Container,
  Fab,
  Grid,
  Stack,
  Typography,
  Button,
  Box,
  LinearProgress,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import RejectIcon from '@mui/icons-material/Cancel';

import api from '../../services/api';
import { Branch, Users } from '../../interfaces';
import CustomModal from '../../components/CustomModal';
import BranchPermitions from './components/BranchPermitions';
import useStorageUser from '../../hooks/useStorageUser';
import Tableusers from './components/Tableusers';

const Permitions: React.FC = () => {
  const [users, setUsers] = React.useState<Users[]>([]);
  const [branchesUserPermitons, setBranchesUserPermitions] =
    React.useState<Branch[]>();
  const [checkedPermitions, setCheckedPermitions] = React.useState<
    Array<Branch>
  >([]);
  const [openModaPermition, setOpenModalPermition] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userSelected, setUserSelected] = React.useState<Users>();

  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (!userParser) return;
    setLoading(true);
    api
      .get('/user', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => setUsers(response.data))
      .catch(error => {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      });
    api
      .get('/branches', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => {
        setLoading(false);
        setBranchesUserPermitions(response.data);
      })
      .catch(error => {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      });
  }, [userParser]);

  const handleOpenModalPermition = (user: Users) => {
    setUserSelected(user);
    setCheckedPermitions(user.branches ? user.branches : []);
    setOpenModalPermition(true);
  };

  const handleSavePermitions = async () => {
    // FIXEME não selecionar as permissões já existentes
    if (userSelected && userParser) {
      setLoading(true);
      const userPermitions = checkedPermitions.map(cp => {
        return { lUserId: userSelected.id, cBranchId: cp.id };
      });
      try {
        const response = await api.post(
          `/auth/permitions/${userSelected.id}`,
          { userPermitions },
          {
            headers: { Authorization: `Barier ${userParser.token}` },
          },
        );
        if (response.status === 201) {
          // Update permitions user list
          const userUpadate = users?.map(user => {
            if (user.id === userSelected.id && user.branches) {
              return {
                ...user,
                branches: checkedPermitions,
              };
            }
            return user;
          });
          setUsers(userUpadate);
          setUserSelected(undefined);
          setCheckedPermitions([]);
          setLoading(false);
          setOpenModalPermition(false);
        }
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    }
  };

  return (
    <Container>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Usuários</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        marginLeft={2}
        marginTop={3}
      >
        <Grid item md={12}>
          <Tableusers
            rows={users}
            loading={loading}
            handlePermition={handleOpenModalPermition}
          />
        </Grid>
      </Grid>
      {(userParser?.lCategory.name === 'owner' ||
        userParser?.lCategory.name === 'admin') && (
        <Fab
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          color="primary"
          aria-label="add"
          onClick={() => navigate('/auth/new')}
        >
          <AddIcon />
        </Fab>
      )}

      <CustomModal
        widht={600}
        open={openModaPermition}
        setOpen={setOpenModalPermition}
      >
        {branchesUserPermitons && userSelected && (
          <BranchPermitions
            branches={branchesUserPermitons}
            userBranches={userSelected.branches ? userSelected.branches : null}
            checkedPermitions={checkedPermitions}
            setCheckedPermitions={setCheckedPermitions}
            userCategory={userParser?.lCategory.name ?? ''}
          />
        )}
        <Stack direction="row" spacing={3} margin={2} justifyContent="center">
          {(userParser?.lCategory.name === 'owner' ||
            userParser?.lCategory.name === 'admin') && (
            <LoadingButton
              disabled={loading}
              loadingPosition="start"
              loading={loading}
              size="large"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSavePermitions}
            />
          )}
          <Button
            onClick={() => setOpenModalPermition(false)}
            variant="contained"
            size="large"
            startIcon={<RejectIcon />}
          />
        </Stack>
      </CustomModal>
    </Container>
  );
};

export default Permitions;
