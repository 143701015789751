import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Typography,
  Grid,
  AlertColor,
  Container,
  Box,
  LinearProgress,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { AxiosError } from 'axios';
import crypto from 'crypto';
import imageCompression from 'browser-image-compression';

import api from '../../../services/api';
import { FilesOS, FilesUpload, VAppointment } from '../../../interfaces';
import OrderServiceActions from '../../../components/OrderServiceAction';
import useStorageUser from '../../../hooks/useStorageUser';
import ImgSignatures from '../../../components/ImgSignatures';
import useDeleteStoredFile from '../../../hooks/useDeleteStoredFile';
import AlertSnackbars from '../../../components/AlertSnackbars';
import GenericVAppointment from './components/GenericVAppointment';
import Bunker from './components/Bunker';

/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum SiloTypeEnum {
  BUNKER = 'bunker',
}

const theme = createTheme();

theme.typography.h3 = {
  marginLeft: 30,
  marginTop: 30,
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

const AppointmentV = () => {
  const [data, setData] = React.useState<VAppointment | null>();
  const [dataImages, setDataImages] = React.useState<FilesOS[]>([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openModalCollected, setOpenModalCollected] =
    React.useState<boolean>(false);
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);
  const [densidadeColetada, setDensidadeColetada] = React.useState<string>('');
  const [fatorCompactacaoColetada, setFatorCompactColetada] =
    React.useState<string>('');
  const [justify, setJustify] = React.useState<string>('');
  const [uploadFiles, setUploadFiles] = React.useState<FilesUpload[]>([]);

  const [messageAlert, setMessageAlert] = React.useState<string>('');
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [typeMessage, setTypeMessage] = React.useState<AlertColor>('success');

  const params = useParams();
  const navigate = useNavigate();
  const { deleteFile } = useDeleteStoredFile();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      setSaving(true);
      if (params.id) {
        api
          .get(`/order_service/${params.id}`, {
            headers: { Authorization: `Barier ${userParser.token}` },
          })
          .then(response => {
            setData(response.data);
            const filesData: FilesOS[] = response.data?.filesOS;
            setDataImages(filesData.filter(f => f.type === 'I'));
            setSaving(false);
            setLoading(false);
          })
          .catch(error => {
            const err = error as AxiosError;
            setSaving(false);
            setLoading(false);
            if (err.response?.status === 401) {
              navigate('/auth/login');
            }
          });
      }
    }
  }, [params, userParser]);

  const handleSaveCollectedValues = async (): Promise<void> => {
    setSaving(true);
    const value = {
      densidadeColetada: Number(densidadeColetada),
      fatorCompactacaoColetada: Number(fatorCompactacaoColetada),
    };
    try {
      const response = await api.put(`/order_services/${params.id}`, value, {
        headers: { Authorization: `Barier ${userParser?.token}` },
      });
      if (response.status === 202) {
        setData(response.data);
        setSaving(false);
        setOpenModalCollected(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      setSaving(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  const handleStatus = async (status: string): Promise<void> => {
    setSaving(true);
    try {
      const response = await api.put(
        `/order_services/${params.id}`,
        {
          status,
          obs: justify,
        },
        {
          headers: { Authorization: `Barier ${userParser?.token}` },
        },
      );
      if (response.status === 202) {
        setOpenAlertSave(true);
        setData(response.data);
      }
      setSaving(false);
    } catch (error) {
      const err = error as AxiosError;
      setSaving(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  // Upload files order services
  const handleUpload = (files: File[]) => {
    const uploadFilesAux = files.map(file => ({
      file,
      id: crypto.randomBytes(16).toString('hex'),
      type: file.type,
      name: file.name,
      readbleSize: file.size,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));
    setUploadFiles(value => [...uploadFilesAux, ...value]);
  };

  const handleDeleteFile = async (id: string) => {
    if (saving) return;
    const isIdStorage = id.includes('/');
    if (isIdStorage && userParser) {
      setSaving(true);
      const deleted = await deleteFile(id, userParser.token);
      if (deleted.success) {
        setDataImages(file => file.filter(f => f.path !== id));
        setTypeMessage('success');
        setMessageAlert(deleted.success);
      } else {
        setTypeMessage('success');
        setMessageAlert(deleted.error ?? 'Não foi possivel remover o arquivo');
      }
      setSaving(false);
      setOpenAlert(true);
    } else {
      const value = uploadFiles.slice().filter(v => v.id !== id);
      setUploadFiles(value);
    }
  };

  const handleUpdateFile = (id: string, value: FilesUpload) => {
    const values = uploadFiles.slice().map(file => {
      return id === file.id ? { ...file, ...value } : file;
    });
    setUploadFiles(values);
  };

  const processUploadFile = async (file: FilesUpload) => {
    const formData = new FormData();
    const img = await imageCompression(file.file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });
    formData.append('file', img, file.name);

    api
      .post(`/order_services_file/${data?.id}`, formData, {
        headers: { Authorization: `Barier ${userParser?.token}` },
        onUploadProgress: (e: ProgressEvent): void => {
          const progress = Math.round(
            (Number(e.loaded) * 100) / Number(e.total),
          );
          handleUpdateFile(file.id, { ...file, progress });
        },
      })
      .then(response => {
        if (response.status === 201) {
          setUploadFiles([]);
          setDataImages(value => [...value, { ...response.data }]);
        }
      })
      .catch(error => {
        handleUpdateFile(file.id, { ...file, error: true });
        const err = error as AxiosError;
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
        setTypeMessage('error');
        setMessageAlert(err.response?.data.message ?? err.message);
        setOpenAlert(true);
      });
  };

  const ContentFactory = (category: string, os: VAppointment) => {
    switch (category) {
      case SiloTypeEnum.BUNKER:
        return <Bunker orderService={os} />;
      default:
        return <GenericVAppointment orderService={os} />;
    }
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
          Silos
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/order_services"
        >
          Ordem de Serviços
        </Link>
        <Typography color="text.primary">Detalhes</Typography>
      </Breadcrumbs>

      {data && ContentFactory(data.silo.categoria.type, data)}

      {data && (
        <OrderServiceActions
          dataImages={dataImages}
          densidadeColetada={densidadeColetada}
          fatorCompactacaoColetada={fatorCompactacaoColetada}
          handleDeleteFile={handleDeleteFile}
          handleSaveCollectedValues={handleSaveCollectedValues}
          handleStatus={handleStatus}
          handleUpload={handleUpload}
          isSaving={saving}
          processUploadFile={processUploadFile}
          setDensidadeColetada={setDensidadeColetada}
          setFatorCompactColetada={setFatorCompactColetada}
          status={data.status}
          uploadFiles={uploadFiles}
          openAlertSave={openAlertSave}
          setOpenAlertSave={setOpenAlertSave}
          openModalCollected={openModalCollected}
          setOpenModalCollected={setOpenModalCollected}
          justify={justify}
          setJustify={setJustify}
        />
      )}

      <Grid item xs={12} md={12} lg={12} marginLeft={2}>
        <ImgSignatures data={data?.filesOS || []} />
      </Grid>

      <AlertSnackbars
        message={messageAlert}
        type={typeMessage}
        open={openAlert}
        setOpen={setOpenAlert}
      />
    </Container>
  );
};

export default AppointmentV;
