import * as React from 'react';

import {
  CardActionArea,
  Grid,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

import { OrderServiceVEProps } from '../interfaces';

interface CardOSVEProps {
  data: OrderServiceVEProps;
  deleteOS: Function;
}

const CardOSVE: React.FC<CardOSVEProps> = props => {
  const { data, deleteOS } = props;
  const navigate = useNavigate();

  let colorCard = '#fff';
  let produto = null;
  if (data.fProductId === 1) {
    produto = 'Soja';
  } else if (data.fProductId === 2) {
    produto = 'Milho';
  } else if (data.fProductId === 3) {
    produto = 'Farelo';
  }

  if (data.status) {
    colorCard = '#ccf0ae';
  } else {
    colorCard = '#eca0a0';
  }
  return (
    <Grid item xs={12} md={6} lg={3}>
      <Card
        sx={{
          bgcolor: colorCard,
        }}
      >
        <CardActionArea onClick={() => navigate(`/extra_volumes/${data.id}`)}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Criado em: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.dataCriacao))}`}
            </Typography>
            <Typography
              sx={{ mb: 1.2, fontSize: 9 }}
              variant="body2"
              color="text.secondary"
            >
              {data.branch.descricao}
            </Typography>
            <Typography variant="h6" component="div">
              OS: {data.os}
            </Typography>
            <Typography variant="body2">{produto}</Typography>
          </CardContent>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {data.matricula}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Sincronização: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.dataSincronizacao))}`}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton
            aria-label="delete"
            size="small"
            style={{ marginRight: 20 }}
            onClick={() => deleteOS(data.id)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardOSVE;
