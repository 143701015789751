import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserStorageInterface } from '../interfaces';

type StrageType = {
  userParser: UserStorageInterface | undefined;
  // eslint-disable-next-line no-unused-vars
  setUserStorage: (userStorage: string) => void;
  validateUserStorage: () => void;
  clearUserStorage: () => void;
};

const useStorageUser = (): StrageType => {
  const navigate = useNavigate();
  const [userParser, setUpserParser] = React.useState<UserStorageInterface>();

  const setUserStorage = (userStorage: string) => {
    setUpserParser(JSON.parse(userStorage));
  };

  const validateUserStorage = () => {
    const userStorage = localStorage.getItem('@storage_user');

    if (!userParser && !userStorage) {
      navigate('/auth/login');
    } else if (!userParser && userStorage) {
      setUpserParser(JSON.parse(userStorage));
    }
  };

  const clearUserStorage = () => {
    localStorage.removeItem('@storage_user');
    setUpserParser(undefined);
    navigate('/auth/login');
  };

  return { userParser, setUserStorage, validateUserStorage, clearUserStorage };
};

export default useStorageUser;
