import * as React from 'react';
import {
  Breadcrumbs,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  AlertColor,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { AxiosError } from 'axios';
import crypto from 'crypto';

import api from '../../../services/api';
import {
  BoxAppointmentInterface,
  FilesOS,
  FilesUpload,
} from '../../../interfaces';
import ImgSignatures from '../../../components/ImgSignatures';
import BoxVolumes from './components/BoxVolumes';
import CardTotalV from '../../../components/CardTotalV';
import OrderServiceActions from '../../../components/OrderServiceAction';
import useStorageUser from '../../../hooks/useStorageUser';
import useDeleteStoredFile from '../../../hooks/useDeleteStoredFile';
import AlertSnackbars from '../../../components/AlertSnackbars';

const AppointmentBox: React.FC = () => {
  const [data, setData] = React.useState<BoxAppointmentInterface>();
  const [dataImages, setDataImages] = React.useState<FilesOS[]>([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [justify, setJustify] = React.useState<string>('');
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = React.useState<FilesUpload[]>([]);

  const [messageAlert, setMessageAlert] = React.useState<string>('');
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [typeMessage, setTypeMessage] = React.useState<AlertColor>('success');

  const params = useParams();
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  const { deleteFile } = useDeleteStoredFile();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      setSaving(true);
      if (params.id) {
        api
          .get(`/order_service/${params.id}`, {
            headers: { Authorization: `Barier ${userParser.token}` },
          })
          .then(async response => {
            setData(response.data);
            const filesData: FilesOS[] = response.data?.filesOS;
            setDataImages(filesData.filter(f => f.type === 'I'));
            setSaving(false);
          })
          .catch(error => {
            const err = error as AxiosError;
            if (err.response?.status === 401) {
              navigate('/auth/login');
            }
          });
      }
    }
  }, [params, userParser]);

  const handleStatus = async (status: string): Promise<void> => {
    setSaving(true);
    try {
      const response = await api.put(
        `/order_services/${params.id}`,
        {
          status,
          obs: justify,
        },
        {
          headers: { Authorization: `Barier ${userParser?.token}` },
        },
      );
      if (response.status === 202) {
        setData(response.data);
        setSaving(false);
      }
      setSaving(false);
    } catch (error) {
      const err = error as AxiosError;
      setSaving(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  // Upload files order services
  const handleUpdateFile = (id: string, value: FilesUpload) => {
    const values = uploadFiles.slice().map(file => {
      return id === file.id ? { ...file, ...value } : file;
    });
    setUploadFiles(values);
  };

  const handleUpload = (files: File[]) => {
    const uploadFilesAux = files.map(file => ({
      file,
      id: crypto.randomBytes(16).toString('hex'),
      type: file.type,
      name: file.name,
      readbleSize: file.size,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));
    setUploadFiles(value => [...uploadFilesAux, ...value]);
  };

  const handleDeleteFile = async (id: string) => {
    if (saving) return;
    const isIdStorage = id.includes('/');
    if (isIdStorage && userParser) {
      setSaving(true);
      const deleted = await deleteFile(id, userParser.token);
      if (deleted.success) {
        setDataImages(file => file.filter(f => f.path !== id));
        setTypeMessage('success');
        setMessageAlert(deleted.success);
      } else {
        setTypeMessage('success');
        setMessageAlert(deleted.error ?? 'Não foi possivel remover o arquivo');
      }
      setSaving(false);
      setOpenAlert(true);
    } else {
      const value = uploadFiles.slice().filter(v => v.id !== id);
      setUploadFiles(value);
    }
  };

  const processUploadFile = async (file: FilesUpload) => {
    const formData = new FormData();
    const img = await imageCompression(file.file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });
    formData.append('file', img, file.name);

    api
      .post(`/order_services_file/${data?.id}`, formData, {
        headers: { Authorization: `Barier ${userParser?.token}` },
        onUploadProgress: (e: ProgressEvent): void => {
          const progress = Math.round(
            (Number(e.loaded) * 100) / Number(e.total),
          );
          handleUpdateFile(file.id, { ...file, progress });
        },
      })
      .then(response => {
        if (response.status === 201) {
          setUploadFiles([]);
          setDataImages(value => [...value, { ...response.data }]);
        }
      })
      .catch(error => {
        handleUpdateFile(file.id, { ...file, error: true });
        const err = error as AxiosError;
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
        setTypeMessage('error');
        setMessageAlert(err.response?.data.message ?? err.message);
        setOpenAlert(true);
      });
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
          Silos
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/order_services"
        >
          Ordem de Serviços
        </Link>
        <Typography color="text.primary">
          Apontamentos Caixa Expedição
        </Typography>
      </Breadcrumbs>
      <Grid container direction="row" spacing={1} marginTop={2} marginLeft={2}>
        {data && data.boxApontamentos && (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        Caixa de expedição
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Caixa</TableCell>
                      <TableCell align="center">A</TableCell>
                      <TableCell align="center">B</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.boxApontamentos
                      .sort((a, b) => a.box - b.box)
                      .map(b => (
                        <TableRow key={b.id}>
                          <TableCell align="left">{b.box}</TableCell>
                          <TableCell align="center">
                            {b.sideA && 'cheia'}
                          </TableCell>
                          <TableCell align="center">
                            {b.sideB && 'cheia'}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {data.silo && (
              <>
                <Grid item xs={6} md={6} lg={6} marginTop={2}>
                  <BoxVolumes
                    title="A"
                    box={data.silo?.oitoes}
                    boxSelected={
                      data.boxApontamentos.filter(b => b.sideA).length
                    }
                    capacityBox={data.silo?.distColunas}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} marginTop={2}>
                  <BoxVolumes
                    title="B"
                    box={data.silo?.oitoes}
                    boxSelected={
                      data.boxApontamentos.filter(b => b.sideB).length
                    }
                    capacityBox={data.silo?.distColunas}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} marginTop={2}>
                  <CardTotalV
                    isAppointment
                    widthLG={2}
                    title="Total"
                    value={data.boxResult.a + data.boxResult.b}
                  />
                </Grid>
              </>
            )}
            {data.filesOS && (
              <Grid item xs={12} md={12} lg={12}>
                <ImgSignatures data={data.filesOS} />
              </Grid>
            )}
          </>
        )}
      </Grid>

      {data && (
        <OrderServiceActions
          dataImages={dataImages}
          processUploadFile={processUploadFile}
          handleDeleteFile={handleDeleteFile}
          handleStatus={handleStatus}
          handleUpload={handleUpload}
          isSaving={saving}
          status={data.status}
          uploadFiles={uploadFiles}
          openAlertSave={openAlertSave}
          setOpenAlertSave={setOpenAlertSave}
          justify={justify}
          setJustify={setJustify}
        />
      )}

      <AlertSnackbars
        message={messageAlert}
        type={typeMessage}
        open={openAlert}
        setOpen={setOpenAlert}
      />
    </>
  );
};

export default AppointmentBox;
