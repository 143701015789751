import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface CardSettingsProps {
  title: string;
  handleNavigation: () => void;
}

const CardSettings = (props: CardSettingsProps) => {
  const { title, handleNavigation } = props;
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card>
        <CardActionArea onClick={handleNavigation}>
          <CardContent>
            <Typography variant="body2">{title}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const Settings: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Typography color="text.primary">configurações</Typography>
      </Breadcrumbs>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <CardSettings
          title="Produtos"
          handleNavigation={() => navigate('/settings/products')}
        />
        <CardSettings
          title="Volume extras"
          handleNavigation={() => navigate('/settings/item_extra_volumes')}
        />
        <CardSettings
          title="e-mails"
          handleNavigation={() => navigate('/settings/emails')}
        />
      </Grid>
    </Container>
  );
};

export default Settings;
