import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import api from '../../services/api';
import { Branch } from '../../interfaces';
import useStorageUser from '../../hooks/useStorageUser';
import CardItem from './components/CardItem';

export type BranchSumary = Partial<Branch> & {
  products: Array<{
    produto: string;
    os: Array<string>;
  }>;
};

const Summary: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [branches, setBranches] = React.useState<BranchSumary[]>([]);
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      setLoading(true);
      api
        .get('/resume/all_branches', {
          headers: { Authorization: `Barier ${userParser.token}` },
        })
        .then(response => {
          setBranches(response.data);
          setLoading(false);
        })
        .catch(error => {
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
          setLoading(false);
        });
    }
  }, [userParser]);

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
          Silos
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Typography color="text.primary">Resumo</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop={2}
      >
        {branches.map(({ products, descricao, id, regiao }) =>
          products.map((product, index) => (
            <CardItem
              descricao={descricao ?? ''}
              id={id}
              regiao={regiao ?? ''}
              produto={product.produto}
              os={product.os}
              key={index.toString()}
            />
          )),
        )}
      </Grid>
    </Container>
  );
};

export default Summary;
