/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid } from '@mui/material';
import { ArmazemMC, MCApontamento, WareHouse } from '../interfaces';
import { ProductType } from '../types';

interface TableAppoitmentMCProps {
  armazem: ArmazemMC;
  mcApontamentos: MCApontamento;
  silo: WareHouse;
  produto: ProductType;
}

const SILO_TYPE_PULMAO_QUERENCIA = 15;
const SILO_TYPE_CONCESILO_QUERENCIA = 9;

const TableAppoitmentMC: React.FC<TableAppoitmentMCProps> = props => {
  const { armazem, mcApontamentos, silo, produto } = props;
  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid item xs={6} md={6} lg={6}>
          <TableContainer
            component={Paper}
            sx={{ marginLeft: 1, marginTop: 2 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Volume do Cone Superior
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Fórmula</TableCell>
                  <TableCell align="center" colSpan={2}>
                    Pi()/3*r²*h
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Dimenssões do Cone
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Altura</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.valueVolumeConeSuperior?.altura)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Diametro</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.valueVolumeConeSuperior?.diametro)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Raio</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.valueVolumeConeSuperior?.raio)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Volume</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.valueVolumeConeSuperior?.volume)}
                  </TableCell>
                  <TableCell align="left">m³</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={6} md={6} lg={6}>
          <TableContainer
            component={Paper}
            sx={{ marginLeft: 1, marginTop: 2 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Volume do Cilindro
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Fórmula</TableCell>
                  <TableCell align="center" colSpan={2}>
                    Pi()*r²*h
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Dimenssões do Cilindro
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Altura</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeCilindro.altura)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Diametro</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeCilindro.diametro)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Raio</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeCilindro.raio)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Volume</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeCilindro.volume)}
                  </TableCell>
                  <TableCell align="left">m³</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TableContainer
            component={Paper}
            sx={{ marginLeft: 1, marginTop: 2 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Cálculo do volume de grãos
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Altura do silo</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.alturaSilo)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Espaço de ar</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.espacoAr)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Altura da massa de grãos</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.alturaMassaGrao)}
                  </TableCell>
                  <TableCell align="left">m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Volume - Cilindro</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.volumeCilindro)}
                  </TableCell>
                  <TableCell align="left">m³</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Volume Total</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.volumeTotal)}
                  </TableCell>
                  <TableCell align="left">m³</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Capacidade Total</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.capacidadeTotalColetada)}
                  </TableCell>
                  <TableCell align="left">
                    {produto?.unidade === 'kg'
                      ? 'ton'
                      : produto?.unidade === 'l'
                      ? 'l'
                      : ''}
                    /m³
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Fundo bisel</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.fundoBisel)}
                  </TableCell>
                  <TableCell align="left">
                    {produto?.unidade === 'kg'
                      ? 'ton'
                      : produto?.unidade === 'l'
                      ? 'l'
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Volume bisel</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.calcVolumeBiselColetada)}
                  </TableCell>
                  <TableCell align="left">
                    {produto?.unidade === 'kg'
                      ? 'ton'
                      : produto?.unidade === 'l'
                      ? 'l'
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Volume total</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(armazem.volumeGrao.totalSiloColetada)}
                  </TableCell>
                  <TableCell align="left">
                    {produto?.unidade === 'kg'
                      ? 'ton'
                      : produto?.unidade === 'l'
                      ? 'l'
                      : ''}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {silo.categoriaId === SILO_TYPE_PULMAO_QUERENCIA ? (
          <Grid item md={6} mt={2}>
            <Paper
              sx={{
                marginLeft: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={`https://flatcalc-files.s3.amazonaws.com/config/querencia/querencia-pulmao-situacao${mcApontamentos.type}.png?w=248&fit=crop&auto=format`}
                srcSet={`https://flatcalc-files.s3.amazonaws.com/querencia/querencia-pulmao-situacao${mcApontamentos.type}.png?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={`situacao${mcApontamentos.type}.png`}
                loading="lazy"
              />
              <img
                src={`https://flatcalc-files.s3.amazonaws.com/config/querencia/querencia-pulmao-tipo${mcApontamentos.type2}.png?w=248&fit=crop&auto=format`}
                srcSet={`https://flatcalc-files.s3.amazonaws.com/config/querencia/querencia-pulmao-tipo${mcApontamentos.type2}.png?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={`tipo${mcApontamentos.type2}.png`}
                loading="lazy"
              />
            </Paper>
          </Grid>
        ) : silo.categoriaId === SILO_TYPE_CONCESILO_QUERENCIA ? (
          <Grid item md={6} mt={2}>
            <Paper
              sx={{
                marginLeft: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={`https://flatcalc-files.s3.amazonaws.com/config/querencia/Situacao${mcApontamentos.type}.png?w=248&fit=crop&auto=format`}
                srcSet={`https://flatcalc-files.s3.amazonaws.com/querencia/Situacao${mcApontamentos.type}.png?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={`situacao${mcApontamentos.type}.png`}
                loading="lazy"
              />
              <img
                src={`https://flatcalc-files.s3.amazonaws.com/config/querencia/SituacaoTipo${mcApontamentos.type2}.png?w=248&fit=crop&auto=format`}
                srcSet={`https://flatcalc-files.s3.amazonaws.com/config/querencia/SituacaoTipo${mcApontamentos.type2}.png?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={`tipo${mcApontamentos.type2}.png`}
                loading="lazy"
              />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={6} md={3} mt={2}>
            <Paper
              sx={{
                marginLeft: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={`https://flatcalc-files.s3.amazonaws.com/type${mcApontamentos.type}.png?w=248&fit=crop&auto=format`}
                srcSet={`https://flatcalc-files.s3.amazonaws.com/type${mcApontamentos.type}.png?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={`type${mcApontamentos.type}.png`}
                loading="lazy"
              />
            </Paper>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default TableAppoitmentMC;
