import React from 'react';
import {
  AlertColor,
  Box,
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import crypto from 'crypto';
import imageCompression from 'browser-image-compression';

import api from '../../../services/api';
import { OrderServiceFirewoods } from '../../../types';
import TableLote from './TableLote';
import { FilesOS, FilesUpload } from '../../../interfaces';
import OrderServiceActions from '../../../components/OrderServiceAction';
import useStorageUser from '../../../hooks/useStorageUser';
import AlertSnackbars from '../../../components/AlertSnackbars';
import useDeleteStoredFile from '../../../hooks/useDeleteStoredFile';

const Details: React.FC = () => {
  const params = useParams();
  const [total, setTotal] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = React.useState<FilesUpload[]>([]);
  const [dataImages, setDataImages] = React.useState<FilesOS[]>([]);
  const [orderService, setOrderService] =
    React.useState<OrderServiceFirewoods>();

  const [messageAlert, setMessageAlert] = React.useState<string>('');
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [typeMessage, setTypeMessage] = React.useState<AlertColor>('success');
  const [justify, setJustify] = React.useState<string>('');

  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  const { deleteFile } = useDeleteStoredFile();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      api
        .get(`/firewood/${params.id}`, {
          headers: { Authorization: `Barier ${userParser?.token}` },
        })
        .then(response => {
          setOrderService(response.data);
          const filesData: FilesOS[] = response.data?.filesFirewood;
          setDataImages(filesData.filter(f => f.type === 'I'));
          setLoading(false);
        })
        .catch(error => {
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
          setTypeMessage('error');
          setMessageAlert(err.response?.data.message ?? err.message);
          setOpenAlert(true);
          setLoading(false);
        });
    }
  }, [params, userParser]);

  const handleStatus = async (status: string): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.put(
        `/firewood/${params.id}`,
        {
          status: status === 'REJEITADO' ? 0 : 1,
        },
        {
          headers: { Authorization: `Barier ${userParser?.token}` },
        },
      );
      if (response.status === 202) {
        const { data } = response;
        setOrderService(data);
        setOpenAlertSave(true);
        setLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      setLoading(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  // Upload files order services
  const handleUpload = (files: File[]) => {
    const uploadFilesAux = files.map(file => ({
      file,
      id: crypto.randomBytes(16).toString('hex'),
      type: file.type,
      name: file.name,
      readbleSize: file.size,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));
    setUploadFiles(value => [...uploadFilesAux, ...value]);
  };

  const handleDeleteFile = async (id: string) => {
    if (loading) return;
    const isIdStorage = id.includes('/');
    if (isIdStorage && userParser) {
      setLoading(true);
      const deleted = await deleteFile(id, userParser.token);
      if (deleted.success) {
        setDataImages(file => file.filter(f => f.path !== id));
        setTypeMessage('success');
        setMessageAlert(deleted.success);
      } else {
        setTypeMessage('success');
        setMessageAlert(deleted.error ?? 'Não foi possivel remover o arquivo');
      }
      setLoading(false);
      setOpenAlert(true);
    } else {
      const value = uploadFiles.slice().filter(v => v.id !== id);
      setUploadFiles(value);
    }
  };

  const handleUpdateFile = (id: string, value: FilesUpload) => {
    const values = uploadFiles.slice().map(file => {
      return id === file.id ? { ...file, ...value } : file;
    });
    setUploadFiles(values);
  };

  const processUploadFile = async (file: FilesUpload) => {
    const formData = new FormData();
    const img = await imageCompression(file.file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });
    formData.append('file', img, file.name);

    try {
      const response = await api.post(
        `/firewood_upload_file/${orderService?.id}`,
        formData,
        {
          headers: { Authorization: `Barier ${userParser?.token}` },
          onUploadProgress: (e: ProgressEvent): void => {
            const progress = Math.round(
              (Number(e.loaded) * 100) / Number(e.total),
            );
            handleUpdateFile(file.id, { ...file, progress });
          },
        },
      );

      if (response.status === 201) {
        setUploadFiles([]);
        setDataImages(value => [...value, { ...response.data }]);
      }
    } catch (error) {
      handleUpdateFile(file.id, { ...file, error: true });
      const err = error as AxiosError;
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
      setTypeMessage('error');
      setMessageAlert(err.response?.data.message ?? err.message);
      setOpenAlert(true);
    }
  };

  function calcTotal(result: number) {
    setTotal(value => value + result);
  }

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/lenha">
          Lenha
        </Link>
        <Typography color="text.primary">Detalhes</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop={2}
      >
        {orderService && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="order service table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Filial</TableCell>
                      <TableCell align="center">OS</TableCell>
                      <TableCell align="center">Usuário</TableCell>
                      <TableCell align="center">Data de criação</TableCell>
                      <TableCell align="center">Estoque</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        {orderService.branch.descricao}
                      </TableCell>
                      <TableCell align="center">{orderService.os}</TableCell>
                      <TableCell align="center">
                        {orderService.matricula}
                      </TableCell>
                      <TableCell align="center">
                        {Intl.DateTimeFormat('pt-BR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(orderService.dataCriacao))}
                      </TableCell>
                      <TableCell align="center">{total.toFixed(2)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {orderService.lotes?.map(lote => (
              <Grid item xs={12} md={6} lg={6} key={lote.id}>
                <TableLote
                  heights={lote.loteHeightFirewood || []}
                  lengthRight={Number(lote.lengthRight)}
                  lengthLeft={Number(lote.lengthLeft)}
                  initialWidth={Number(lote.initialWidth)}
                  finalWidth={Number(lote.finalWidth)}
                  lote={lote.line}
                  getResult={result => calcTotal(result)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      {orderService && (
        <OrderServiceActions
          dataImages={dataImages}
          processUploadFile={processUploadFile}
          handleDeleteFile={handleDeleteFile}
          handleStatus={handleStatus}
          handleUpload={handleUpload}
          isSaving={loading}
          status={orderService.status ? 'APROVADO' : 'REJEITADO'}
          uploadFiles={uploadFiles}
          openAlertSave={openAlertSave}
          setOpenAlertSave={setOpenAlertSave}
          justify={justify}
          setJustify={setJustify}
        />
      )}

      <AlertSnackbars
        message={messageAlert}
        type={typeMessage}
        open={openAlert}
        setOpen={setOpenAlert}
      />
    </Container>
  );
};

export default Details;
