import * as React from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  Fab,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import CustomModal from '../../../components/CustomModal';
import api from '../../../services/api';
import useStorageUser from '../../../hooks/useStorageUser';

interface ProductInterface {
  id: number;
  descricao: string;
  unidade: string;
}

const Products: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openModalNewProduct, setOpenModalNewProduct] =
    React.useState<boolean>(false);
  const [listProduct, setListProduct] = React.useState<Array<ProductInterface>>(
    [],
  );
  const [descricao, setDescricao] = React.useState<string>('');
  const [unidade, setUnidade] = React.useState<string>('');

  const navigate = useNavigate();

  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (!userParser) return;
    setLoading(true);
    api
      .get('/products', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => {
        setListProduct(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        const err = error as AxiosError;
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      });
  }, [userParser]);

  const handleSaveProduct = async () => {
    if (descricao !== '' && userParser) {
      setLoading(true);
      try {
        const response = await api.post(
          '/product',
          { descricao, unidade },
          {
            headers: { Authorization: `Barier ${userParser.token}` },
          },
        );
        if (response.status === 201) {
          setListProduct(value => (value ? [...value, response.data] : value));
          setOpenModalNewProduct(false);
          setDescricao('');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/settings"
        >
          configurações
        </Link>
        <Typography color="text.primary">produtos</Typography>
      </Breadcrumbs>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Grid item md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Unidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listProduct.map(data => (
                    <TableRow key={data.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>{data.descricao}</TableCell>
                      <TableCell>{data.unidade}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>

      <Fab
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        color="primary"
        aria-label="add"
        onClick={() => setOpenModalNewProduct(true)}
      >
        <AddIcon />
      </Fab>

      <CustomModal
        widht={600}
        open={openModalNewProduct}
        setOpen={setOpenModalNewProduct}
      >
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              required
              id="outlined-required"
              label="descrição"
              value={descricao}
              onChange={txt => setDescricao(txt.target.value)}
            />
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="descrição"
              value={unidade}
              onChange={txt => setUnidade(txt.target.value)}
            />
          </div>
        </Box>
        <Stack direction="row" spacing={3} margin={2} justifyContent="center">
          <LoadingButton
            loadingPosition="start"
            loading={loading}
            size="large"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveProduct}
          >
            Salvar
          </LoadingButton>
        </Stack>
      </CustomModal>
    </Container>
  );
};

export default Products;
