import * as React from 'react';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import AlertSnackbars from '../../../components/AlertSnackbars';
import api from '../../../services/api';
import {
  Branch,
  Categoria,
  NewWareHouse,
  WareHouse,
} from '../../../interfaces';
import useStorageUser from '../../../hooks/useStorageUser';

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormSilo: React.FC = () => {
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [categoria, setcategoria] = React.useState<string>('');
  const [cBranchId, setcBranchId] = React.useState<string>('');
  const [descricao, setdescricao] = React.useState<string>('');
  const [oitoes, setoitoes] = React.useState<string>('');
  const [larguraA, setlarguraA] = React.useState<string>('');
  const [alturaCoberturaB, setalturaCoberturaB] = React.useState<string>('');
  const [alturaParedeC, setalturaParedeC] = React.useState<string>('');
  const [alturaTaludeD, setalturaTaludeD] = React.useState<string>('');
  const [larguraPisoE, setlarguraPisoE] = React.useState<string>('');
  const [comprimentoF, setcomprimentoF] = React.useState<string>('');
  const [comprimentoPisoG, setcomprimentoPisoG] = React.useState<string>('');
  const [distColunas, setdistColunas] = React.useState<string>('');
  const [saving, setSeving] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<Categoria[]>();
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);
  const [messageSuccess, setMessageSuccess] = React.useState<string>('');
  const [openAlertError, setOpenAlertError] = React.useState<boolean>(false);
  const [messageError, setMessageError] = React.useState<string>('');

  const params = useParams();
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (!userParser) return;
    if (params.id) {
      api
        .get(`/silo/${params.id}`, {
          headers: { Authorization: `Barier ${userParser.token}` },
        })
        .then(response => {
          if (response.status === 200) {
            const silo: WareHouse = response.data;
            setcategoria(silo.categoria.id?.toString());
            setcBranchId(silo.cBranchId?.toString());
            setdescricao(silo.descricao?.toString());
            setoitoes(silo.oitoes?.toString());
            setlarguraA(silo.larguraA?.toString());
            setalturaCoberturaB(silo.alturaCoberturaB?.toString());
            setalturaParedeC(silo.alturaParedeC?.toString());
            setalturaTaludeD(silo.alturaTaludeD?.toString());
            setlarguraPisoE(silo.larguraPisoE?.toString());
            setcomprimentoF(silo.comprimentoF?.toString());
            setcomprimentoPisoG(silo.comprimentoPisoG?.toString());
            setdistColunas(silo.distColunas?.toString());
          }
        })
        .catch(error => {
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
        });
    }
    api
      .get('/branches', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => {
        setBranches(response.data);
      })
      .catch(error => {
        const err = error as AxiosError;
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      });

    api
      .get('/silo_categories', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        const err = error as AxiosError;
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      });
  }, [userParser]);

  const saveSilo = async () => {
    if (!userParser || userParser.lCategory.name === 'standard') return;

    setSeving(true);
    try {
      const data: NewWareHouse = {
        descricao,
        categoriaId: Number(categoria),
        cBranchId: Number(cBranchId),
        oitoes: Number(oitoes),
        larguraA: Number(larguraA),
        alturaCoberturaB: Number(alturaCoberturaB),
        alturaParedeC: Number(alturaParedeC),
        alturaTaludeD: Number(alturaTaludeD),
        larguraPisoE: Number(larguraPisoE),
        comprimentoF: Number(comprimentoF),
        comprimentoPisoG: Number(comprimentoPisoG),
        distColunas: Number(distColunas),
      };
      if (params.id) {
        await api.put(`/silo/${params.id}`, data, {
          headers: { Authorization: `Barier ${userParser.token}` },
        });
        setMessageSuccess('Silo atualizado com sucesso');
      } else {
        await api.post('/silos', data, {
          headers: { Authorization: `Barier ${userParser.token}` },
        });
        setMessageSuccess('Silo cadastrado com sucesso');
      }
      setOpenAlertSave(true);
      setSeving(false);
    } catch (error) {
      const err = error as AxiosError;
      setSeving(false);
      setOpenAlertError(true);
      setMessageError(
        `Operação realizada com sucesso! \n Código do erro: ${error}`,
      );
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ color: 'inherit', textDecoration: 'none', marginLeft: 10 }}
          to="/"
        >
          Silos
        </Link>
        <Typography color="text.primary">
          {params.id ? 'Atualizar' : 'Novo'} Silo
        </Typography>
      </Breadcrumbs>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '50ch' },
          bgcolor: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            required
            id="outlined-required"
            label="Descrição"
            value={descricao}
            onChange={txt => setdescricao(txt.target.value)}
          />
          <FormControl sx={{ m: 1, width: '50ch' }}>
            <InputLabel id="demo-multiple-name-label">Categoria</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              input={<OutlinedInput label="Categoria" />}
              MenuProps={MenuProps}
              value={categoria}
              onChange={txt => setcategoria(txt.target.value)}
            >
              {categories &&
                categories.map(category => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.description}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: '50ch' }}>
            <InputLabel id="demo-multiple-name-label">Unidade</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              input={<OutlinedInput label="Unidade" />}
              MenuProps={MenuProps}
              value={cBranchId}
              onChange={txt => setcBranchId(txt.target.value)}
            >
              {branches.map(branch => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            required
            id="outlined-required"
            label="Quantidade de Oitões"
            type="number"
            value={oitoes}
            onChange={txt => setoitoes(txt.target.value)}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required outlined-number"
            label="Largura A"
            type="number"
            value={larguraA}
            onChange={txt => setlarguraA(txt.target.value)}
          />
          <TextField
            required
            id="outlined-number"
            label="Altura Cobertura B"
            type="number"
            value={alturaCoberturaB}
            onChange={txt => setalturaCoberturaB(txt.target.value)}
          />
          <TextField
            required
            id="outlined-required outlined-number"
            label="Altura Parede C"
            type="number"
            value={alturaParedeC}
            onChange={txt => setalturaParedeC(txt.target.value)}
          />

          <TextField
            required
            id="outlined-required outlined-number"
            label="Altura Talude D"
            type="number"
            value={alturaTaludeD}
            onChange={txt => setalturaTaludeD(txt.target.value)}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required outlined-number"
            label="Largura Piso E"
            type="number"
            value={larguraPisoE}
            onChange={txt => setlarguraPisoE(txt.target.value)}
          />
          <TextField
            required
            id="outlined-required outlined-number"
            label="Comprimento F"
            type="number"
            value={comprimentoF}
            onChange={txt => setcomprimentoF(txt.target.value)}
          />
          <TextField
            required
            id="outlined-required outlined-number"
            label="Comprimento Piso G"
            type="number"
            value={comprimentoPisoG}
            onChange={txt => setcomprimentoPisoG(txt.target.value)}
          />
          <TextField
            required
            id="outlined-required outlined-number"
            label="Dist. entre colunas"
            type="number"
            value={distColunas}
            onChange={txt => setdistColunas(txt.target.value)}
          />
        </div>

        <Stack direction="row" spacing={6} margin={10}>
          {(userParser?.lCategory.name === 'owner' ||
            userParser?.lCategory.name === 'admin') && (
            <LoadingButton
              loadingPosition="start"
              loading={saving}
              size="large"
              variant="contained"
              startIcon={params.id ? <EditIcon /> : <SaveIcon />}
              onClick={saveSilo}
            >
              {params.id ? 'Editar' : 'Salvar'}
            </LoadingButton>
          )}
          <Button
            size="large"
            variant="contained"
            startIcon={<CancelIcon />}
            color="secondary"
            onClick={() => navigate('/')}
          >
            Cancelar
          </Button>
        </Stack>
      </Box>
      <AlertSnackbars
        type="success"
        open={openAlertSave}
        setOpen={setOpenAlertSave}
        message={messageSuccess}
      />
      <AlertSnackbars
        type="error"
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={messageError}
      />
    </>
  );
};

export default FormSilo;
