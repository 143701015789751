/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Stack,
  Button,
  Box,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RejectIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import FiletIcon from '@mui/icons-material/AttachFile';
import SaveIcon from '@mui/icons-material/Save';
import { FaMicroscope } from 'react-icons/fa';

import UploadFiles from './UploadFiles/UploadFiles';
import CustomModal from './CustomModal';
import { FilesOS, FilesUpload } from '../interfaces';
import AlertSaveSuccess from './CustomizedSnackbars';

interface ActionsProps {
  status: string;
  isSaving: boolean;
  densidadeColetada?: string;
  fatorCompactacaoColetada?: string;
  uploadFiles: FilesUpload[];
  dataImages: FilesOS[];
  openAlertSave: boolean;
  openModalCollected?: boolean;
  justify: string;
  handleStatus: (status: string) => void;
  setDensidadeColetada?: (value: string) => void;
  setFatorCompactColetada?: (value: string) => void;
  handleSaveCollectedValues?: () => void;
  handleUpload: (files: File[]) => void;
  handleDeleteFile: (id: string) => void;
  processUploadFile: (file: FilesUpload) => Promise<void>;
  setOpenAlertSave: (isOpen: boolean) => void;
  setOpenModalCollected?: (isOpen: boolean) => void;
  setJustify: (value: string) => void;
}

const OrderServiceActions: React.FC<ActionsProps> = actionProps => {
  const {
    status,
    isSaving,
    densidadeColetada,
    fatorCompactacaoColetada,
    uploadFiles,
    dataImages,
    openAlertSave,
    openModalCollected,
    justify,
    handleStatus,
    setDensidadeColetada,
    setFatorCompactColetada,
    handleSaveCollectedValues,
    handleUpload,
    handleDeleteFile,
    processUploadFile,
    setOpenAlertSave,
    setOpenModalCollected,
    setJustify,
  } = actionProps;

  const navigate = useNavigate();

  const [openModaUpload, setOpenModalUpload] = React.useState<boolean>(false);
  const [openModalReject, setOpenModalReject] = React.useState<boolean>(false);
  const [enableJustify, setEnableJustify] = React.useState<boolean>(false);
  const [isProgress, setProgress] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isSaving || !uploadFiles.some(f => f.progress > 0 && f.progress < 100))
      setProgress(false);
  }, [isSaving, uploadFiles]);

  const saveFiles = () => {
    if (uploadFiles.some(f => f.progress === 0) && uploadFiles.length > 0) {
      setProgress(true);
      uploadFiles.forEach(processUploadFile);
    }
  };

  const handleJustify = (txt: React.ChangeEvent<HTMLInputElement>) => {
    setJustify(txt.target.value);
    const inputText = txt.target.value;

    const wordValidator = /\b\w+\b/;
    const isValidLength = inputText.length > 10 && inputText.length < 255;
    const containsValidWords = wordValidator.test(inputText);

    if (isValidLength && containsValidWords) setEnableJustify(true);
    else setEnableJustify(false);
  };

  const handleReject = () => {
    if (enableJustify) {
      handleStatus('REJEITADO');
      setOpenModalReject(false);
    }
  };

  return (
    <>
      {status !== 'REJEITADO' ? (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={5}
        >
          <Stack direction="row" spacing={3}>
            <Button
              onClick={() => setOpenModalUpload(true)}
              size="large"
              variant="contained"
              startIcon={<FiletIcon />}
              color="success"
            >
              Anexar
            </Button>
            {status === 'ANALISE' && (
              <LoadingButton
                disabled={isSaving}
                onClick={() => handleStatus('APROVADO')}
                loadingPosition="start"
                loading={isSaving}
                size="large"
                variant="contained"
                startIcon={<CheckIcon />}
              >
                Aprovar
              </LoadingButton>
            )}
            {setOpenModalCollected && (
              <LoadingButton
                disabled={isSaving}
                onClick={() => setOpenModalCollected(true)}
                loadingPosition="start"
                loading={isSaving}
                size="large"
                variant="contained"
                startIcon={<FaMicroscope />}
              >
                Dados Coletados
              </LoadingButton>
            )}
            <LoadingButton
              disabled={isSaving}
              onClick={() => setOpenModalReject(true)}
              color="secondary"
              loadingPosition="start"
              loading={isSaving}
              size="large"
              variant="contained"
              startIcon={<RejectIcon />}
            >
              Rejeitar
            </LoadingButton>
          </Stack>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={5}
        >
          <LoadingButton
            disabled={isSaving}
            onClick={() => {
              handleStatus('ARQUIVADO');
              navigate(-1);
            }}
            color="error"
            loadingPosition="start"
            loading={isSaving}
            size="large"
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Arquivar
          </LoadingButton>
        </Grid>
      )}

      <AlertSaveSuccess
        open={openAlertSave}
        setOpen={setOpenAlertSave}
        message="Status da ordem de serviço alterado!"
      />
      {setOpenModalCollected &&
        openModalCollected &&
        setDensidadeColetada &&
        setFatorCompactColetada && (
          <CustomModal
            widht={400}
            open={openModalCollected}
            setOpen={setOpenModalCollected}
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              marginTop={2}
            >
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': {
                    m: 1,
                    width: '35ch',
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  required
                  id="outlined-required outlined-number"
                  label="Densidade Coletada"
                  value={densidadeColetada}
                  onChange={txt => setDensidadeColetada(txt.target.value)}
                  type="number"
                />
                <TextField
                  required
                  id="outlined-number"
                  label="Fator de Compactação"
                  type="number"
                  value={fatorCompactacaoColetada}
                  onChange={txt => setFatorCompactColetada(txt.target.value)}
                />
              </Box>
              <Stack direction="row" spacing={3}>
                <LoadingButton
                  disabled={isSaving}
                  onClick={handleSaveCollectedValues}
                  loadingPosition="start"
                  loading={isSaving}
                  size="large"
                  variant="contained"
                  startIcon={<SaveIcon />}
                />
                <Button
                  onClick={() => setOpenModalCollected(false)}
                  variant="contained"
                  size="large"
                  startIcon={<RejectIcon />}
                />
              </Stack>
            </Grid>
          </CustomModal>
        )}

      <CustomModal
        widht={600}
        open={openModaUpload}
        setOpen={setOpenModalUpload}
      >
        <UploadFiles
          onUpload={handleUpload}
          files={uploadFiles}
          filesOs={dataImages}
          onDeleteFile={handleDeleteFile}
        />
        <Stack direction="row" spacing={3} margin={2} justifyContent="center">
          {isProgress ? (
            <CircularProgress />
          ) : (
            <>
              <LoadingButton
                disabled={isSaving}
                onClick={saveFiles}
                loadingPosition="start"
                loading={isSaving}
                size="large"
                variant="contained"
                startIcon={<SaveIcon />}
              />
              <Button
                onClick={() => setOpenModalUpload(false)}
                variant="contained"
                size="large"
                startIcon={<RejectIcon />}
              />
            </>
          )}
        </Stack>
      </CustomModal>

      <CustomModal
        widht={800}
        open={openModalReject}
        setOpen={setOpenModalReject}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': {
                m: 1,
                width: '70ch',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl variant="standard">
              <TextField
                id="outlined-multiline-flexible"
                label="Justificativa"
                value={justify}
                multiline
                maxRows={4}
                onChange={handleJustify}
              />
              <FormHelperText
                id="standard-weight-helper-text"
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                {`O campo deve ter no mínimo 10 caracteres e no máximo 254 [${justify.length} tamanho atual].`}
              </FormHelperText>
            </FormControl>
          </Box>
          <Stack direction="row" spacing={3}>
            <LoadingButton
              disabled={isSaving && !enableJustify}
              color={enableJustify ? 'primary' : 'inherit'}
              onClick={handleReject}
              loadingPosition="start"
              loading={isSaving}
              size="large"
              variant="contained"
              startIcon={<SaveIcon />}
            />
            <Button
              onClick={() => setOpenModalReject(false)}
              variant="contained"
              size="large"
              startIcon={<RejectIcon />}
            />
          </Stack>
        </Grid>
      </CustomModal>
    </>
  );
};

export default OrderServiceActions;
