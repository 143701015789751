import * as React from 'react';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import CardTotalV from '../../../../components/CardTotalV';
import { MCAppointment, FlatcalcOil } from '../../../../interfaces';

type OrderProps = {
  orderService: MCAppointment;
};

type FlatcalcProps = {
  flatcalc: FlatcalcOil;
  productUnity: string;
};

const TableFlatcalc: React.FC<FlatcalcProps> = ({ flatcalc, productUnity }) => (
  <>
    <TableContainer component={Paper} sx={{ marginLeft: 1, marginTop: 2 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              Volume do Cilindro
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Fórmula</TableCell>
            <TableCell align="center" colSpan={2}>
              Pi()*r²*h
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} align="center">
              Dimenssões do Cilindro
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Altura</TableCell>
            <TableCell align="right">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2,
              }).format(flatcalc.cylinder.altura)}
            </TableCell>
            <TableCell align="left">m</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Diametro</TableCell>
            <TableCell align="right">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2,
              }).format(flatcalc.cylinder.diametro)}
            </TableCell>
            <TableCell align="left">m</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Raio</TableCell>
            <TableCell align="right">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2,
              }).format(flatcalc.cylinder.raio)}
            </TableCell>
            <TableCell align="left">m</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Volume</TableCell>
            <TableCell align="right">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2,
              }).format(flatcalc.cylinder.volume)}
            </TableCell>
            <TableCell align="left">m³</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <TableContainer component={Paper} sx={{ marginLeft: 1, marginTop: 2 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              Cálculo do volume
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Volume do cilindro</TableCell>
            <TableCell align="right">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2,
              }).format(flatcalc.cylinder.volume)}
            </TableCell>
            <TableCell align="left">m³</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Volume total</TableCell>
            <TableCell align="right">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2,
              }).format(flatcalc.volumeLiters)}
            </TableCell>
            <TableCell align="left">{productUnity}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const Oil: React.FC<OrderProps> = ({ orderService }) => {
  const flatcalc = orderService.flatcalc as FlatcalcOil;
  return (
    <>
      <Grid container direction="row" spacing={1} marginLeft={3} marginTop={2}>
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Altura"
          value={Number(orderService.silo.larguraA)}
        />
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Diâmetro"
          value={Number(orderService.silo.alturaCoberturaB)}
        />

        <CardTotalV
          isAppointment
          widthLG={2}
          title="Temperatura (ºC)"
          value={orderService.mcApontamentos?.centro}
        />
        <CardTotalV
          isAppointment
          widthLG={2}
          title="Nível até topo"
          value={orderService.mcApontamentos?.lateral}
        />
      </Grid>
      <Grid container direction="row" spacing={1} marginLeft={3} marginTop={2}>
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Densidade"
          value={flatcalc.densityTonCubic.density ?? 0}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={4}>
        {orderService.flatcalc && (
          <TableFlatcalc
            productUnity={
              orderService.produto.unidade === 'kg'
                ? 'ton'
                : orderService.produto.unidade
            }
            flatcalc={flatcalc}
          />
        )}
      </Grid>
    </>
  );
};

export default Oil;
