export default (dataInicial: Date, dataFinal: Date) => {
  const umDiaEmMilissegundos = 86400000; // 24 horas * 60 minutos * 60 segundos * 1000 milissegundos

  const dateFinalParser = new Date(dataFinal).getTime();
  const dateInitialParser = new Date(dataInicial).getTime();

  const diferencaEmMilissegundos = dateFinalParser - dateInitialParser;
  const diffDays = Math.round(diferencaEmMilissegundos / umDiaEmMilissegundos);

  return diffDays;
};
