import * as React from 'react';

import {
  CardActionArea,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  CardActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

import { GeometricShape } from '../types';

type CardGSProps = {
  data: GeometricShape;
  removeGeometricShape: Function;
};

const CardGeometricShape: React.FC<CardGSProps> = props => {
  const { data, removeGeometricShape } = props;
  const navigate = useNavigate();

  let colorCard = '#fff';
  if (data.status === 'APROVADO') {
    colorCard = '#ccf0ae';
  } else if (data.status === 'REJEITADO') {
    colorCard = '#eca0a0';
  }

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Card
        sx={{
          bgcolor: colorCard,
        }}
      >
        <CardActionArea
          onClick={() => navigate(`/geometric_shapes/${data.id}`)}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Criado em: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.createdTime))}`}
            </Typography>
            <Typography
              sx={{ mb: 1.2, fontSize: 9 }}
              variant="body2"
              color="text.secondary"
            >
              {data.branch.descricao}
            </Typography>
            <Typography variant="h6" component="div">
              OS: {data.os}
            </Typography>
            <Typography variant="body2">{data.produto.descricao}</Typography>
          </CardContent>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {data.matricula}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Sincronização: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.updatedTime))}`}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton
            aria-label="delete"
            size="small"
            style={{ marginRight: 20 }}
            onClick={() => removeGeometricShape(data.id)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardGeometricShape;
