import * as React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { VAppointment } from '../../../../interfaces';
import MeasuringPointTable from '../../../../components/MeasuringPointTable';
import CardTotalV from '../../../../components/CardTotalV';

type GenericProps = {
  orderService: VAppointment;
};

const theme = createTheme();

theme.typography.h3 = {
  marginLeft: 30,
  marginTop: 30,
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

const GenericVAppointment: React.FC<GenericProps> = ({ orderService }) => (
  <>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      marginLeft={4}
    >
      <Stack direction="row" spacing={3}>
        {orderService.vApontamentos && (
          <>
            <MeasuringPointTable
              vApontamentos={orderService.vApontamentos}
              title="C + D"
              measuringPoint={orderService.memoryCalc.cd}
              type="left"
            />
            <MeasuringPointTable
              vApontamentos={orderService.vApontamentos}
              title="B + C + D"
              measuringPoint={orderService.memoryCalc.bcd}
              type="center"
            />
            <MeasuringPointTable
              vApontamentos={orderService.vApontamentos}
              title="C + D"
              measuringPoint={orderService.memoryCalc.cd}
              type="right"
            />
          </>
        )}
      </Stack>
    </Grid>

    <ThemeProvider theme={theme}>
      <Typography variant="h3">Cálculo do Trapezóide (Talude)</Typography>
    </ThemeProvider>
    {orderService.memoryCalc && (
      <>
        <Grid container direction="row" spacing={2} marginLeft={2}>
          <CardTotalV
            isAppointment={false}
            widthLG={6}
            title="LD - Comprimento do Trapézio Lateral Direito (Sem Produto)"
            value={orderService.memoryCalc.volumeTrapezioFrenteFundoDireita}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={6}
            title="LE - Comprimento Trapézio Lateral Esq (Sem Produto)"
            value={orderService.memoryCalc.volumeTrapezioFrenteFundoEsquerda}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={6}
            title="LA Largura Trapézio Frente (Sem Produto)"
            value={orderService.memoryCalc.volumeTrapezioFrente}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={6}
            title="LB - Largura Trapézio Fundo (Sem Produto)"
            value={orderService.memoryCalc.volumeTrapezioFundo}
          />
        </Grid>
        <Grid
          container
          direction="row"
          spacing={2}
          marginLeft={2}
          marginTop={2}
        >
          <CardTotalV
            isAppointment={false}
            widthLG={3}
            title="LD - Desconto Lateral Direito"
            value={orderService.calcWarehouse.discountRight}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={3}
            title="LE - Desconto Lateral Esquerdo"
            value={orderService.calcWarehouse.discountLeft}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={3}
            title="LA - Desconto Frente"
            value={orderService.calcWarehouse.discountFront}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={3}
            title="LB - Desconto Fundo"
            value={orderService.calcWarehouse.discountBack}
          />
          {orderService.calcWarehouse.discountInnerWall > 0 && (
            <CardTotalV
              isAppointment={false}
              widthLG={3}
              title="Desconto Parede Interna"
              value={orderService.calcWarehouse.discountInnerWall}
            />
          )}
        </Grid>

        <ThemeProvider theme={theme}>
          <Typography variant="h3">Descontos</Typography>
        </ThemeProvider>
        <Grid container direction="row" spacing={2} marginLeft={2}>
          <CardTotalV
            isAppointment={false}
            widthLG={4}
            title="Mts Cúbicos Total sem Desconto Trapézio"
            value={orderService.calcWarehouse.volumeTotalProduto}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={4}
            title="Desconto do Talude Semi-V - (Trapezoide)"
            value={orderService.memoryCalc.descontoTaludeSemiV}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={4}
            title="Mts Cúbicos Total com Desconto"
            value={orderService.calcWarehouse.metrosCubicoTotalComDesconto}
          />
        </Grid>
        <ThemeProvider theme={theme}>
          <Typography variant="h3">
            Totais com fator de compactação e densidade
          </Typography>
        </ThemeProvider>
        <Grid container direction="row" spacing={2} marginLeft={2}>
          <CardTotalV
            isAppointment={false}
            widthLG={4}
            title="Densidade"
            value={orderService.densidadeColetada}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={4}
            title="Fator de Compactação"
            value={orderService.fatorCompactacaoColetada}
          />
          <CardTotalV
            isAppointment
            widthLG={4}
            title="TOTAL Kg no Ármazem"
            value={orderService.calcWarehouse.totalArmazemColetada * 1000}
          />
        </Grid>
      </>
    )}
  </>
);

export default GenericVAppointment;
