import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { OrderServiceFirewoods } from '../../../types';

type DataProps = {
  data: OrderServiceFirewoods;
  // eslint-disable-next-line no-unused-vars
  deleteOS: (id: number) => {};
};

const CardFirewood: React.FC<DataProps> = ({ data, deleteOS }) => {
  let colorCard = '#fff';
  if (data.status) {
    colorCard = '#ccf0ae';
  } else {
    colorCard = '#eca0a0';
  }

  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Card
        sx={{
          bgcolor: colorCard,
        }}
      >
        <CardActionArea onClick={() => navigate(`/lenha/${data.id}`)}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Criado em: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.dataCriacao))}`}
            </Typography>
            <Typography
              sx={{ mb: 1.2, fontSize: 9 }}
              variant="body2"
              color="text.secondary"
            >
              {data.branch.descricao}
            </Typography>
            <Typography variant="h6" component="div">
              OS: {data.os}
            </Typography>
            <Typography variant="body2">{data.produto.descricao}</Typography>
          </CardContent>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {data.matricula}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Sincronização: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.dataSincronizacao))}`}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton
            aria-label="delete"
            size="small"
            style={{ marginRight: 20 }}
            onClick={() => deleteOS(data.id)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default CardFirewood;
