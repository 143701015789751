/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Dropzone from 'react-dropzone';
import {
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import filesize from 'filesize';

import {
  Content,
  DropContainer,
  UploadMessage,
  ContainerFiles,
  FileInfo,
  Preview,
} from './styles';
import { FilesOS, FilesUpload } from '../../interfaces';

const urlAPI = process.env.REACT_APP_URL_API;
interface UploadFilesProps {
  onUpload: (files: File[]) => void;
  onDeleteFile: (id: string) => void;
  files: FilesUpload[];
  filesOs: FilesOS[];
}

const UploadFiles: React.FC<UploadFilesProps> = props => {
  const { onUpload, files, onDeleteFile, filesOs } = props;
  const [filesSizeTotal, setFilesSizeTotal] = React.useState<number>(0);

  // Customized component isDrag active or reject
  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      return (
        <UploadMessage type="default">
          Selecione ou arraste os arquivos aqui...
        </UploadMessage>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }
    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  };

  const handlerImage = (path: string) => {
    if (path.includes('s3.amazonaws.com')) return path;
    return `${urlAPI}/files/${encodeURIComponent(path)}`;
  };

  React.useEffect(() => {
    let totalSize = 0;
    async function fetchFileSizes() {
      const requests = filesOs.map(async file => {
        const response = await fetch(handlerImage(file.path), {
          method: 'HEAD',
        });
        const contentLength = response.headers.get('content-length');
        const sizeInBytes = contentLength ? Number(contentLength) : null;

        if (sizeInBytes !== null) {
          const sizeInMB = sizeInBytes / (1024 * 1024);
          totalSize += Number(sizeInMB.toFixed(2));
        }
      });

      await Promise.all(requests);
      setFilesSizeTotal(totalSize);
    }

    fetchFileSizes();
  }, [filesOs, setFilesSizeTotal]);

  return (
    <Grid container direction="column" alignItems="center" marginTop={2}>
      <Content>
        <Dropzone
          accept={['image/png', 'image/jpeg']}
          onDropAccepted={onUpload}
          multiple={false}
          maxSize={1048576 * 10}
        >
          {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
            <DropContainer
              {...getRootProps()}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
            >
              <input {...getInputProps()} />
              {renderDragMessage(isDragAccept, isDragReject)}
            </DropContainer>
          )}
        </Dropzone>
        <Typography mt={2} fontSize={11} color="green">
          O tamanho atual de todos os arquivos anexados é de{' '}
          {filesSizeTotal.toFixed(2)} MB. O limite máximo permitido é de 5 MB
          para os anexos.
        </Typography>
        {files.map(file => (
          <ContainerFiles key={file.id}>
            <li>
              <FileInfo>
                <Preview src={file.preview} />
                <div>
                  <Link
                    underline="hover"
                    href={file.preview}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>{file.name}</strong>
                  </Link>

                  {!file.uploaded && (
                    <span>
                      {filesize(file.readbleSize)}
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => onDeleteFile(file.id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </span>
                  )}
                </div>
              </FileInfo>

              <div>
                {!file.uploaded && !file.error && (
                  <CircularProgress
                    size={24}
                    variant="determinate"
                    value={file.progress}
                  />
                )}
                {file.uploaded && <CheckCircleIcon color="success" />}
                {file.error && <ErrorIcon color="error" />}
              </div>
            </li>
          </ContainerFiles>
        ))}

        {filesOs.map(file => (
          <ContainerFiles key={file.id}>
            <li>
              <FileInfo>
                <Preview src={handlerImage(file.path)} />
                <Link
                  underline="hover"
                  href={handlerImage(file.path)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <strong>{file.name}</strong>
                  </div>
                </Link>
              </FileInfo>
              <div>
                <CheckCircleIcon color="success" />
              </div>
              {file.id && (
                <span>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => onDeleteFile(file.path)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </span>
              )}
            </li>
          </ContainerFiles>
        ))}
      </Content>
    </Grid>
  );
};

export default UploadFiles;
