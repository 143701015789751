/* eslint-disable react/no-array-index-key */
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AxiosError } from 'axios';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import FilterReport from '../components/FilterReport';
import useStorageUser from '../../../hooks/useStorageUser';

type DataReportType = {
  resume: number;
  totalContabil: number;
  outrasFiliaisTerceiros: number;
  estoqueTerceiros: number;
  flatcalc: number;
  accounting: number;
};

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
});

const PhisicalVsAccounting: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [product, setproduct] = React.useState<string>('');
  const [dataReport, setDataReport] = React.useState<any>({});
  const [branch, setBranch] = React.useState<string>('');
  const [year, setYear] = React.useState<string>('');
  const [month, setMonth] = React.useState<string>('');

  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    const getReport = async () => {
      if (!userParser || branch === '' || product === '') return;
      setLoading(true);
      const period =
        year !== '' && month !== '' ? `&period=${year}-${month}` : '';

      const params = `?cBranchId=${branch}&products=${product}${period}`;
      try {
        const response = await api.get(`/report/phisical_accounting${params}`, {
          headers: { Authorization: `Barier ${userParser.token}` },
        });
        if (response.status === 200) {
          setDataReport(response.data);
        }

        setLoading(false);
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    };
    getReport();
  }, [year, month, branch, product]);

  const handleProduct = (value: String) => {
    setproduct(value.toString());
  };

  const handleBranch = (value: String) => {
    setBranch(value.toString());
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/report">
          Relatórios
        </Link>
        <Typography color="text.primary">Físico vs Contábil</Typography>
      </Breadcrumbs>

      <FilterReport
        branch={branch}
        product={product}
        handleBranch={handleBranch}
        handleProduct={handleProduct}
        setLoading={setLoading}
        handleMonth={setMonth}
        month={month}
        handleYear={setYear}
        year={year}
      />
      <Grid container spacing={2} marginTop={2}>
        <Grid item xl={12} md={12}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              size="small"
              aria-label="report"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  {Object.keys(dataReport).map(value => (
                    <TableCell
                      key={value}
                      colSpan={dataReport[value].length}
                      align="center"
                    >
                      {value !== '' ? value : 'Not defined'}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Phisical Inv.</TableCell>
                  {Object.keys(dataReport).map(value =>
                    dataReport[value].map((d: DataReportType) => (
                      <TableCell key={`${value}-phisical-${d.flatcalc}`}>
                        {Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                        }).format(d.flatcalc)}
                      </TableCell>
                    )),
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>Accounting Inv.</TableCell>
                  {Object.keys(dataReport).map(value =>
                    dataReport[value].map((d: DataReportType, key: number) => (
                      <TableCell key={`${value}-${key}-accounting`}>
                        {Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                        }).format(d.accounting)}
                      </TableCell>
                    )),
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>Difference</TableCell>
                  {Object.keys(dataReport).map(value =>
                    dataReport[value].map((d: DataReportType, key: number) => (
                      <TableCell key={`${value}-${key}-difference`}>
                        {Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                        }).format(d.accounting - d.flatcalc)}
                      </TableCell>
                    )),
                  )}
                </TableRow>
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell>Diff%</TableCell>
                  {Object.keys(dataReport).map(value =>
                    dataReport[value].map((d: DataReportType, key: number) => (
                      <TableCell key={`${value}-${key}-diff`}>
                        {Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                        }).format(
                          d.flatcalc !== 0 || d.accounting !== 0
                            ? (d.flatcalc / d.accounting) * 100 - 100
                            : 0,
                        )}{' '}
                        %
                      </TableCell>
                    )),
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PhisicalVsAccounting;
