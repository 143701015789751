import React from 'react';
import { styled, Toolbar, IconButton, Typography, Badge } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useStorageUser from '../../../hooks/useStorageUser';

interface AppBarProps {
  open: boolean;
  toggleDrawer: any;
}

interface MyAppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const MyAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<MyAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar: React.FC<AppBarProps> = props => {
  const { open, toggleDrawer } = props;
  const { clearUserStorage } = useStorageUser();

  const handleExitApp = () => {
    clearUserStorage();
  };

  return (
    <MyAppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Flat Calc
        </Typography>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton color="inherit" onClick={handleExitApp}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </MyAppBar>
  );
};

export default AppBar;
