import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { ResumeProps } from '../interfaces';

interface TableResumeProps {
  totalContabil: number;
  otherBranchParties: number;
  inventoryParties: number;
  dataInventory: ResumeProps;
  dateContabil: string | null;
  dateResume: string;
}

const PRODUTO_CAVACO = 5;
const PRODUTO_LENHA = 6;

const TableResume: React.FC<TableResumeProps> = props => {
  const {
    dataInventory: { branch, inventories, product, totalInventory },
    totalContabil,
    otherBranchParties,
    inventoryParties,
    dateContabil,
    dateResume,
  } = props;
  const totalOtherVolume =
    totalContabil + otherBranchParties - inventoryParties;

  const RowsResumeDetail = () =>
    product.id === (PRODUTO_CAVACO || PRODUTO_LENHA) ? (
      <>
        <TableRow>
          <TableCell component="td" scope="row">
            {`Contábil de ${product?.descricao} (saldo anterior) ... `}
            {dateContabil && dateContabil !== ''
              ? Intl.DateTimeFormat('pt-BR', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  timeZone: 'UTC',
                }).format(new Date(dateContabil))
              : ''}
          </TableCell>

          <TableCell component="td" scope="row">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(totalContabil)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="td" scope="row">
            {`Entradas (informação das NF's recebidas) `}
          </TableCell>

          <TableCell component="td" scope="row">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(otherBranchParties)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            component="td"
            scope="row"
            variant="head"
            style={{ fontWeight: 'bold' }}
          >
            {`Total Contábil de ${product.descricao} até ... `}
            {Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              timeZone: 'UTC',
            }).format(new Date(dateResume))}
          </TableCell>

          <TableCell component="td" scope="row" style={{ fontWeight: 'bold' }}>
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(totalOtherVolume)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
      </>
    ) : (
      <>
        <TableRow>
          <TableCell
            component="td"
            scope="row"
            variant="head"
            style={{ fontWeight: 'bold' }}
          >
            Total pelo inventário
          </TableCell>

          <TableCell component="td" scope="row" style={{ fontWeight: 'bold' }}>
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(totalInventory)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="td" scope="row">
            Estoque em poder de terceiros
          </TableCell>

          <TableCell component="td" scope="row">
            (-)
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(inventoryParties)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="td" scope="row">
            Total Contábil
          </TableCell>

          <TableCell component="td" scope="row">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(totalContabil)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="td" scope="row">
            Outras Filias e/ou Terceiros
          </TableCell>

          <TableCell component="td" scope="row">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(otherBranchParties)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            component="td"
            scope="row"
            variant="head"
            style={{ fontWeight: 'bold' }}
          >
            Total Volume Fiscal (Filial + Outros)
          </TableCell>

          <TableCell component="td" scope="row" style={{ fontWeight: 'bold' }}>
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3,
            }).format(totalOtherVolume)}
            {` `} {product.unidade}
          </TableCell>
        </TableRow>
      </>
    );

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <TableContainer component={Paper}>
          <Table stickyHeader size="small" aria-label="teste">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  Resumo do inventário de {product.descricao} em{' '}
                  {branch.descricao}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventories.map((item, index) => (
                <TableRow key={`resume-table-${index.toString()}`}>
                  <TableCell component="td" scope="row">
                    {item.description}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {item.operation === 1 ? '(+) ' : '(-) '}
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3,
                    }).format(item.value)}
                  </TableCell>
                </TableRow>
              ))}

              <RowsResumeDetail />

              <TableRow
                sx={{ '&:last-child td, &:last-child td': { border: 0 } }}
              >
                <TableCell component="td" scope="row">
                  Diferença
                </TableCell>
                <TableCell component="td" scope="row">
                  {Intl.NumberFormat('pt-BR', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3,
                  }).format(totalInventory - totalOtherVolume)}
                  {' = '}
                  {totalOtherVolume > 0
                    ? Intl.NumberFormat('pt-BR', {
                        maximumFractionDigits: 2,
                      }).format((totalInventory / totalOtherVolume) * 100 - 100)
                    : 0}
                  %
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default TableResume;
