import {
  Box,
  Breadcrumbs,
  Container,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Users as UserInterface } from '../../../interfaces';
import api from '../../../services/api';
import useStorageUser from '../../../hooks/useStorageUser';
import ToolbarExportToCSV from '../../../components/ToolbarExportToCSV';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    width: 200,
  },
  {
    field: 'username',
    headerName: 'Username',
    width: 100,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 90,
  },
  {
    field: 'categoria',
    headerName: 'Category',
    valueGetter: params => params.row?.lCategory.name,
    width: 90,
  },
  {
    field: 'branches',
    headerName: 'Company',
    valueGetter: params =>
      params.row?.branches.length > 0 ? params.row?.branches[0].empresa : '',
    width: 200,
  },
  {
    field: 'createdAt',
    headerName: 'Created by',
    type: 'dateTime',
    width: 200,
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated by',
    type: 'dateTime',
    width: 160,
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: 'lastLogin',
    headerName: 'Last login',
    type: 'dateTime',
    width: 200,
    valueGetter: ({ value }) => value && new Date(value),
  },
];

const Users: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataRows, setDataRows] = React.useState<Array<UserInterface>>([]);
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    const getReports = async () => {
      if (!userParser) return;
      setLoading(true);
      const headerSet = {
        headers: { Authorization: `Barier ${userParser.token}` },
      };

      try {
        const response = await api.get('/reports/users', headerSet);
        setDataRows(response.data);
        setLoading(false);
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);

        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    };
    getReports();
  }, [userParser]);

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/report">
          Relatórios
        </Link>
        <Typography color="text.primary">Users</Typography>
      </Breadcrumbs>

      <Box sx={{ height: 700, width: '100%' }} component={Paper} marginTop={2}>
        <DataGrid
          rows={dataRows}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          disableRowSelectionOnClick
          slots={{
            toolbar: () => ToolbarExportToCSV({ name: 'report-users' }),
          }}
        />
      </Box>
    </Container>
  );
};

export default Users;
