import * as React from 'react';
import { Box, LinearProgress, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

import CardBranches from '../../components/CardBranches';
import api from '../../services/api';
import { Branch } from '../../interfaces';
import useStorageUser from '../../hooks/useStorageUser';

const Branches: React.FC = () => {
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      api
        .get('/branches', {
          headers: { Authorization: `Barier ${userParser.token}` },
        })
        .then(response => {
          setBranches(response.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [userParser]);

  return (
    <>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {branches.map(branch => (
        <CardBranches data={branch} key={branch.id} />
      ))}
      <Fab
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        color="primary"
        aria-label="add"
        onClick={() => navigate('/branches/new')}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default Branches;
