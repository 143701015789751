import * as React from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup } from '@mui/material';
import Paper from '@mui/material/Paper';

import { Branch } from '../../../interfaces';

interface BranchPermitionsProps {
  branches: Array<Branch>;
  userBranches: Array<Branch> | null;
  checkedPermitions: Array<Branch>;
  setCheckedPermitions: Function;
  userCategory: string;
}

const BranchPermitions: React.FC<BranchPermitionsProps> = props => {
  const {
    branches,
    userBranches,
    checkedPermitions,
    setCheckedPermitions,
    userCategory,
  } = props;

  const checkedPermition = (id: number) => {
    if (userBranches) {
      const item = userBranches.find(p => p.id === id);
      if (item) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleToggle = (value: Branch, checked: boolean) => {
    if (checked) {
      setCheckedPermitions((cp: Array<Branch>) => [...cp, value]);
    } else {
      const p = checkedPermitions.filter(v => v.id !== value.id);
      setCheckedPermitions(p);
    }
  };

  const customList = () => (
    <Paper sx={{ width: 500, maxHeight: 500, overflow: 'auto' }}>
      <FormGroup style={{ padding: 12 }}>
        {branches.map(value => (
          <FormControlLabel
            key={value.id}
            control={
              <Checkbox
                defaultChecked={checkedPermition(value.id)}
                disabled={userCategory !== 'owner' && userCategory !== 'admin'}
              />
            }
            label={value.descricao}
            onChange={(event: any) => handleToggle(value, event.target.checked)}
          />
        ))}
      </FormGroup>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList()}</Grid>
    </Grid>
  );
};
export default BranchPermitions;
