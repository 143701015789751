import * as React from 'react';

import {
  Box,
  Breadcrumbs,
  Container,
  LinearProgress,
  Typography,
  Paper,
  Fab,
  CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { TreeItem, TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReviewsIcon from '@mui/icons-material/Reviews';

import useStorageUser from '../../../hooks/useStorageUser';
import AlertSaveError from '../../../components/CustomizedSnackbarsError';
import AlertSaveSuccess from '../../../components/CustomizedSnackbars';

import api from '../../../services/api';

const ControllingSubordinates: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [controllerships, setControllerships] = React.useState<Array<any>>([]);
  const [openAlertError, setOpenAlertError] = React.useState<boolean>(false);
  const [messageAlertErro, setmessageAlertErro] = React.useState<string>('');
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);
  const [messageAlertSuccess, setmessageAlertSuccess] =
    React.useState<string>('');
  const [isReview, setReview] = React.useState<boolean>(false);

  const { userParser, validateUserStorage } = useStorageUser();

  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    async function getControllers() {
      if (!userParser) {
        return;
      }

      try {
        const response = await api.get('/reports/controlling_subordinates', {
          headers: { Authorization: `Barier ${userParser.token}` },
        });
        setControllerships(response.data);
      } catch (error) {
        const e = error as AxiosError;
        setOpenAlertError(true);
        setmessageAlertErro(e.message);
      } finally {
        setLoading(false);
      }
    }
    getControllers();
  }, [userParser]);

  const ticketAcessReview = async () => {
    if (!userParser) {
      return;
    }
    setReview(true);
    try {
      const response = await api.get('/reports/ticket_acess_review', {
        headers: { Authorization: `Barier ${userParser.token}` },
      });

      setOpenAlertSave(true);
      setmessageAlertSuccess(response.data.message);
    } catch (error) {
      const e = error as AxiosError;
      setOpenAlertError(true);
      setmessageAlertErro(e.message);
    } finally {
      setReview(false);
    }
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/report">
          Relatórios
        </Link>
        <Typography color="text.primary">
          Subordinados da controladoria
        </Typography>
      </Breadcrumbs>

      <Box sx={{ width: '100%' }} component={Paper} marginTop={2}>
        <TreeView
          aria-label="Controlling Subordinates"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {controllerships.map(controller => (
            <TreeItem nodeId={controller.id} label={controller.name}>
              {controller.branches.map((branch: any) => (
                <TreeItem
                  nodeId={`${controller.id}-${branch.id}`}
                  label={branch.descricao}
                >
                  {branch.users.map((user: any) => (
                    <TreeItem
                      nodeId={`${controller.id}-${branch.id}-${user.id}`}
                      label={`nome: ${user.name} | email: ${user.email}  | categoria: ${user.lCategory.name}`}
                    />
                  ))}
                </TreeItem>
              ))}
            </TreeItem>
          ))}
        </TreeView>
      </Box>
      {userParser &&
        (userParser.lCategory.name === 'owner' ||
          userParser.lCategory.name === 'admin') && (
          <Fab
            color="primary"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            onClick={ticketAcessReview}
          >
            {isReview ? (
              <CircularProgress size={32} color="inherit" />
            ) : (
              <ReviewsIcon />
            )}
          </Fab>
        )}
      <AlertSaveSuccess
        open={openAlertSave}
        setOpen={setOpenAlertSave}
        message={messageAlertSuccess}
      />
      <AlertSaveError
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={messageAlertErro}
      />
    </Container>
  );
};

export default ControllingSubordinates;
