import {
  Box,
  Breadcrumbs,
  Container,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import DetailIcon from '@mui/icons-material/RemoveRedEyeSharp';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
  Title,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import {
  ValueScale,
  Animation,
  EventTracker,
  BarSeries,
  ArgumentScale,
} from '@devexpress/dx-react-chart';
import { scaleBand } from '@devexpress/dx-chart-core';

import { OrderService } from '../../../interfaces';
import api from '../../../services/api';
import useStorageUser from '../../../hooks/useStorageUser';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'os',
    headerName: 'OS',
    width: 120,
  },
  {
    field: 'region',
    headerName: 'Region',
    valueGetter: params => params.row?.silo.branch.regiao,
    width: 90,
  },
  {
    field: 'branch',
    headerName: 'Subsidiary',
    valueGetter: params => params.row?.silo.branch.descricao,
    width: 200,
  },
  {
    field: 'silo',
    headerName: 'Description',
    valueGetter: params => params.row?.silo.descricao,
    width: 150,
  },
  {
    field: 'categoria',
    headerName: 'Category',
    valueGetter: params => params.row?.silo.categoria.description,
    width: 150,
  },
  {
    field: 'produto',
    headerName: 'Product',
    width: 100,
    valueGetter: params => params.row?.produto?.descricao,
  },
  {
    field: 'matricula',
    headerName: 'Created by',
  },
  {
    field: 'dataCriacao',
    headerName: 'Date created',
    type: 'dateTime',
    width: 200,
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: 'obs',
    width: 500,
    filterable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id, row }) => {
      const navigate = useNavigate();
      let type = '';

      if (row?.silo?.categoria?.type === 'armazem') type = 'appointment_v';
      else type = 'appointment_mc';

      return [
        <GridActionsCellItem
          icon={<DetailIcon />}
          label="Detail"
          className="textPrimary"
          onClick={() => navigate(`/order_services/${type}/${id}`)}
          color="inherit"
        />,
      ];
    },
  },
];

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);

const Rejections: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataRows, setDataRows] = React.useState<Array<OrderService>>([]);
  const [dataMonth, setDataMonth] = React.useState<
    Array<{ key: string; value: number }>
  >([]);
  const [dataBranch, setDataBranch] = React.useState<
    Array<{ key: string; value: number }>
  >([]);
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    const getReports = async () => {
      if (!userParser) return;
      setLoading(true);
      const headerSet = {
        headers: { Authorization: `Barier ${userParser.token}` },
      };

      Promise.all([
        api
          .get('/report/rejections', headerSet)
          .then(response => response.data),
        api
          .get('/report/rejections/chart?month=true', headerSet)
          .then(response => response.data),
        api
          .get('/report/rejections/chart?branch=true', headerSet)
          .then(response => response.data),
      ])
        .then(results => {
          setLoading(false);
          setDataRows(results[0]);
          setDataMonth(results[1]);
          setDataBranch(results[2]);
        })
        .catch(error => {
          const err = error as AxiosError;
          setLoading(false);

          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
        });
    };
    getReports();
  }, [userParser]);

  React.useEffect(() => {
    const getReports = async () => {
      if (!userParser) return;
      setLoading(true);
      try {
        const response = await api.get('/report/rejections/chart', {
          headers: { Authorization: `Barier ${userParser.token}` },
        });
        if (response.status === 200) {
          setDataMonth(response.data);
        }

        setLoading(false);
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    };
    getReports();
  }, [userParser]);

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/report">
          Relatórios
        </Link>
        <Typography color="text.primary">Rejeições</Typography>
      </Breadcrumbs>

      <Box component={Paper} marginTop={2}>
        <Chart data={dataMonth}>
          <ValueScale />
          <ArgumentAxis />
          <ValueAxis />

          <LineSeries valueField="value" argumentField="key" />
          <Title text="Service orders rejected monthly" />
          <EventTracker />
          <Tooltip />
          <Animation />
        </Chart>
      </Box>

      <Box component={Paper} marginTop={2}>
        <Chart data={dataBranch.sort((a, b) => a.value - b.value)} rotated>
          <ArgumentScale factory={scaleBand} />
          <ArgumentAxis />
          <ValueAxis />

          <BarSeries valueField="value" argumentField="descricao" />
          <Title text="Service orders rejected branch" />
          <EventTracker />
          <Tooltip />
          <Animation />
        </Chart>
      </Box>

      <Box sx={{ height: 700, width: '100%' }} component={Paper} marginTop={2}>
        <DataGrid
          rows={dataRows}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Box>
    </Container>
  );
};

export default Rejections;
