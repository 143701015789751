/* eslint-disable no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { products } from '../../../Branches/Resume/SelectProduct';

const FilterPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 120,
  display: 'flex',
  alignContent: 'center',
  justifyItems: 'center',
  alignItems: 'center',
}));

const months = [
  { key: 'Janeiro', value: '01' },
  { key: 'Fevereiro', value: '02' },
  { key: 'Março', value: '03' },
  { key: 'Abril', value: '04' },
  { key: 'Maio', value: '05' },
  { key: 'Junho', value: '06' },
  { key: 'Julho', value: '07' },
  { key: 'Agosto', value: '08' },
  { key: 'Setembro', value: '09' },
  { key: 'Outubro', value: '10' },
  { key: 'Novembro', value: '11' },
  { key: 'Dezembro', value: '12' },
];

const YearsItemMenu = () => {
  const yearNow = new Date().getFullYear();
  const yearStart = 2022;
  const deltaYear = yearNow - yearStart;

  const arraYear = Array.from(
    { length: deltaYear + 1 },
    (_, i) => yearStart + i,
  );

  return arraYear.map(year => (
    <MenuItem key={year} value={year}>
      {year}
    </MenuItem>
  ));
};

type FilterProps = {
  handleProduct: (value: String) => void;
  product: String;
  handlerMonth: (value: String) => void;
  month: String;
  handlerYear: (value: String) => void;
  year: String;
};

const FilterReport: React.FC<FilterProps> = props => {
  const { handleProduct, product, handlerMonth, handlerYear, month, year } =
    props;

  return (
    <Grid container spacing={2} paddingTop={2}>
      <Grid item xs={12} md={12} sm={6}>
        <FilterPaper elevation={1}>
          <FormControl sx={{ m: 1, width: 100 }}>
            <InputLabel id="year-label">Ano</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              onChange={event => handlerYear(event.target.value)}
            >
              {YearsItemMenu()}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 200 }}>
            <InputLabel id="month-label">Mês referência</InputLabel>
            <Select
              labelId="month-label"
              id="month"
              value={month}
              onChange={event => handlerMonth(event.target.value)}
            >
              {months.map(mth => (
                <MenuItem key={mth.value} value={mth.value}>
                  {mth.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="product-label">Produto</InputLabel>
            <Select
              labelId="product-label"
              id="product"
              value={product}
              onChange={event => handleProduct(event.target.value)}
            >
              {products.map(productItem => (
                <MenuItem key={productItem.id} value={productItem.id}>
                  {productItem.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FilterPaper>
      </Grid>
    </Grid>
  );
};

export default FilterReport;
