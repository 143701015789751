import * as React from 'react';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import CardTotalV from '../../../../components/CardTotalV';
import { MCAppointment, FlatcalcTank } from '../../../../interfaces';

type TankProps = {
  orderService: MCAppointment;
};

type BiodieselProps = {
  flatcalc: FlatcalcTank;
};

const TableBiodiesel: React.FC<BiodieselProps> = ({ flatcalc }) => (
  <TableContainer component={Paper} sx={{ marginLeft: 1, marginTop: 2 }}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Fundo até Nível</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.BottomLevel))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Real</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.real))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Volume (litros)</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.volumeLiters))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Densidade (ton/m3)</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.densityTonCubic.density))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Volume kg</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.kilo))}
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  </TableContainer>
);

const Tank: React.FC<TankProps> = ({ orderService }) => {
  const flatcalc = orderService.flatcalc as FlatcalcTank;
  return (
    <>
      <Grid container direction="row" spacing={1} marginLeft={3} marginTop={2}>
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Diâmetro"
          value={Number(orderService.silo.larguraA)}
        />
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Altura"
          value={Number(orderService.silo.alturaCoberturaB)}
        />
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Capacidade"
          value={Number(orderService.silo.comprimentoPisoG)}
        />
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Área da base"
          value={Number(orderService.silo.distColunas)}
        />

        <CardTotalV
          isAppointment
          widthLG={2}
          title="Temperatura (ºC)"
          value={orderService.mcApontamentos?.centro}
        />
        <CardTotalV
          isAppointment
          widthLG={2}
          title="Nível até topo"
          value={orderService.mcApontamentos?.lateral}
        />
      </Grid>
      <Grid container direction="row" spacing={1} marginLeft={3} marginTop={2}>
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Densidade"
          value={flatcalc.densityTonCubic.density ?? 0}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={4}>
        {orderService.flatcalc && <TableBiodiesel flatcalc={flatcalc} />}
      </Grid>
    </>
  );
};

export default Tank;
