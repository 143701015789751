import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import OrderSericesIcon from '@mui/icons-material/Description';
import ExtraVolumesIcon from '@mui/icons-material/AddBusiness';
import GeometricShapeIcon from '@mui/icons-material/SquareFoot';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import BranchIcon from '@mui/icons-material/DomainDisabled';
import BarChartIcon from '@mui/icons-material/BarChart';
import ConfigIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import SummaryIcon from '@mui/icons-material/Summarize';
import { List } from '@mui/material';

import { Link } from 'react-router-dom';
import { UserStorageInterface } from '../../../interfaces';

const styles = {
  link: { color: 'inherit', textDecoration: 'none' },
};

interface ItemInterface {
  user: UserStorageInterface;
}

export const MainListItems: React.FC<ItemInterface> = ({ user }) => (
  <List>
    {user.lCategory.name !== 'gbs' && (
      <>
        <Link style={styles.link} to="/branches">
          <ListItem button>
            <ListItemIcon>
              <BranchIcon />
            </ListItemIcon>
            <ListItemText primary="Filiais" />
          </ListItem>
        </Link>
        <Link style={styles.link} to="/">
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Silos" />
          </ListItem>
        </Link>
        <Link style={styles.link} to="/order_services">
          <ListItem button>
            <ListItemIcon>
              <OrderSericesIcon />
            </ListItemIcon>
            <ListItemText primary="Ordens de Serviços" />
          </ListItem>
        </Link>
        <Link style={styles.link} to="/extra_volumes">
          <ListItem button>
            <ListItemIcon>
              <ExtraVolumesIcon />
            </ListItemIcon>
            <ListItemText primary="Volumes Extras" />
          </ListItem>
        </Link>
        <Link style={styles.link} to="/geometric_shapes">
          <ListItem button>
            <ListItemIcon>
              <GeometricShapeIcon />
            </ListItemIcon>
            <ListItemText primary="Formas Geométricas" />
          </ListItem>
        </Link>
        <Link style={styles.link} to="/lenha">
          <ListItem button>
            <ListItemIcon>
              <LocalFireDepartmentIcon />
            </ListItemIcon>
            <ListItemText primary="Lenha" />
          </ListItem>
        </Link>
        <Link style={styles.link} to="/summary">
          <ListItem button>
            <ListItemIcon>
              <SummaryIcon />
            </ListItemIcon>
            <ListItemText primary="Resumo" />
          </ListItem>
        </Link>
      </>
    )}
  </List>
);

export const SecondaryListItems: React.FC<ItemInterface> = ({ user }) => (
  <List>
    {user.lCategory.name !== 'standard' && (
      <>
        <ListSubheader inset>Painel administrativo</ListSubheader>
        <Link style={styles.link} to="/report">
          <ListItem button>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios" />
          </ListItem>
        </Link>
        {(user.lCategory.name === 'owner' ||
          user.lCategory.name === 'admin') && (
          <>
            <Link style={styles.link} to="/settings">
              <ListItem button>
                <ListItemIcon>
                  <ConfigIcon />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </ListItem>
            </Link>
            <Link style={styles.link} to="/auth/users">
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Permissões" />
              </ListItem>
            </Link>
          </>
        )}
      </>
    )}
  </List>
);
