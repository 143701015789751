import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import { OrderServiceVEProps } from '../../interfaces';
import CardOSVE from '../../components/CardOSVE';
import DialogDelete from '../../components/DialogDelete';
import useStorageUser from '../../hooks/useStorageUser';

const OrderServiceExtraVolume: React.FC = () => {
  const [selectedIDDelete, setSelectedIDDelete] = React.useState<
    number | undefined
  >();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openDialogDelete, setOpenDialogDelete] =
    React.useState<boolean>(false);

  const [orderServices, setOrderServices] =
    React.useState<OrderServiceVEProps[]>();

  const params = useParams();
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      const uri = params.id
        ? `/order_services_branch_extra_volumes?cBranchId=${params.id}`
        : '/order_services_extra_volumes';
      api
        .get(uri, {
          headers: { Authorization: `Barier ${userParser.token}` },
        })
        .then(response => {
          setOrderServices(response.data);
          setLoading(false);
        })
        .catch(error => {
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
          setLoading(false);
        });
    }
  }, [params, userParser]);

  const handleOpenDialogDelete = (id: number) => {
    setSelectedIDDelete(id);
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
    setSelectedIDDelete(undefined);
  };

  const deleteOS = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.delete(
        `/order_services_extra_volumes/${selectedIDDelete}`,
        {
          headers: { Authorization: `Barier ${userParser?.token}` },
        },
      );
      if (response.status === 202 && response.data) {
        setOrderServices(
          values => values && values.filter(os => os.id !== selectedIDDelete),
        );
        handleCloseDialogDelete();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Typography color="text.primary">Ordens de Serviços</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop={2}
      >
        {orderServices &&
          orderServices.map(os => (
            <CardOSVE data={os} key={os.id} deleteOS={handleOpenDialogDelete} />
          ))}
      </Grid>

      <DialogDelete
        handleClose={handleCloseDialogDelete}
        open={openDialogDelete}
        handleConfirm={deleteOS}
      />
    </Container>
  );
};

export default OrderServiceExtraVolume;
