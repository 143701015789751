import * as React from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import CustomModal from '../../../components/CustomModal';
import api from '../../../services/api';
import useStorageUser from '../../../hooks/useStorageUser';

interface ItemInterface {
  id?: number;
  type: string;
  operation: boolean;
}

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ItemExtraVolumes: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openModalNewItem, setOpenModalNewItem] =
    React.useState<boolean>(false);
  const [listItem, setListItem] = React.useState<Array<ItemInterface>>();
  const [type, setType] = React.useState<string>('');
  const [operation, setOperation] = React.useState<string>('');

  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (!userParser) return;
    setLoading(true);
    api
      .get('/items_ve', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => {
        setListItem(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userParser]);

  const handleSaveItem = async () => {
    if (!userParser) return;
    if ((type !== '' && operation === 'true') || operation === 'false') {
      setLoading(true);
      try {
        const operationChange = operation === 'true';
        const response = await api.post(
          '/item_ve',
          { type, operation: operationChange },
          {
            headers: { Authorization: `Barier ${userParser.token}` },
          },
        );
        if (response.status === 201) {
          setListItem(value => (value ? [...value, response.data] : value));
          setOpenModalNewItem(false);
          setType('');
          setOperation('');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/settings"
        >
          configurações
        </Link>
        <Typography color="text.primary">produtos</Typography>
      </Breadcrumbs>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Grid item md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Operação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listItem &&
                    listItem.map(data => (
                      <TableRow key={data.id}>
                        <TableCell>{data.type}</TableCell>
                        <TableCell>
                          {data.operation ? 'Somar' : 'Subtrair'}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>

      <Fab
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        color="primary"
        aria-label="add"
        onClick={() => setOpenModalNewItem(true)}
      >
        <AddIcon />
      </Fab>

      <CustomModal
        widht={600}
        open={openModalNewItem}
        setOpen={setOpenModalNewItem}
      >
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              required
              id="outlined-required"
              label="descrição"
              value={type}
              onChange={txt => setType(txt.target.value)}
            />
            <FormControl sx={{ m: 1, width: '50ch' }}>
              <InputLabel id="demo-multiple-name-label">Operação</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                input={<OutlinedInput label="Unidade" />}
                MenuProps={MenuProps}
                value={operation}
                onChange={txt => setOperation(txt.target.value)}
              >
                <MenuItem value="true">Somar</MenuItem>
                <MenuItem value="false">Subtrair</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
        <Stack direction="row" spacing={3} margin={2} justifyContent="center">
          <LoadingButton
            loadingPosition="start"
            loading={loading}
            size="large"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveItem}
          >
            Salvar
          </LoadingButton>
        </Stack>
      </CustomModal>
    </Container>
  );
};

export default ItemExtraVolumes;
