import * as React from 'react';
import {
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Box,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  AlertColor,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import crypto from 'crypto';
import imageCompression from 'browser-image-compression';

import { FilesGS, GeometricShape } from '../../../types';
import api from '../../../services/api';
import ImgSignatures from '../../../components/ImgSignatures';
import { FilesOS, FilesUpload } from '../../../interfaces';
import OrderServiceActions from '../../../components/OrderServiceAction';
import useStorageUser from '../../../hooks/useStorageUser';
import AlertSnackbars from '../../../components/AlertSnackbars';
import useDeleteStoredFile from '../../../hooks/useDeleteStoredFile';

const ItemGeometricShape: React.FC = () => {
  const [itemGS, setItemGS] = React.useState<GeometricShape>();
  const [filesGS, setFilesGS] = React.useState<FilesGS[]>([]);
  const [dataImages, setDataImages] = React.useState<FilesOS[]>([]);
  const [densidade, setDensidade] = React.useState<string>('');
  const [fatorCompactacao, setFatorCompactacao] = React.useState<string>('');
  const [justify, setJustify] = React.useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = React.useState<FilesUpload[]>([]);
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);

  const [messageAlert, setMessageAlert] = React.useState<string>('');
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [typeMessage, setTypeMessage] = React.useState<AlertColor>('success');

  const params = useParams();
  const navigate = useNavigate();
  const { deleteFile } = useDeleteStoredFile();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      setLoading(true);
      api
        .get(`/geometric_shape/${params.id}`, {
          headers: { Authorization: `Barier ${userParser.token}` },
        })
        .then(response => {
          const { data } = response;
          setItemGS(data);
          const filesData: FilesOS[] = data?.filesGS;
          setDataImages(filesData.filter(f => f.type === 'I'));
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [params, userParser]);

  const handleStatus = async (status: string): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.put(
        `/geometric_shape/${params.id}`,
        {
          status,
          obs: justify,
        },
        {
          headers: { Authorization: `Barier ${userParser?.token}` },
        },
      );
      if (response.status === 202) {
        const { data } = response;
        setItemGS(data);
        setFilesGS(data.filesGS);
        setOpenModal(false);
        setOpenAlertSave(true);
        setLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      setLoading(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  const saveCollected = async () => {
    setLoading(true);
    if (densidade !== '' && fatorCompactacao !== '') {
      const value = {
        densidadeColetada: Number(densidade),
        fatorCompactacaoColetada: Number(fatorCompactacao),
      };
      try {
        const response = await api.put(`/geometric_shape/${params.id}`, value, {
          headers: { Authorization: `Barier ${userParser?.token}` },
        });
        const { data } = response;
        if (response.status === 202) {
          setItemGS(data);
          setFilesGS(data.filesGS);
          setOpenModal(false);
          setLoading(false);
        }
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    }
  };

  // Upload files order services
  const handleUpload = (files: File[]) => {
    const uploadFilesAux = files.map(file => ({
      file,
      id: crypto.randomBytes(16).toString('hex'),
      type: file.type,
      name: file.name,
      readbleSize: file.size,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));
    setUploadFiles(value => [...uploadFilesAux, ...value]);
  };

  const handleDeleteFile = async (id: string) => {
    if (loading) return;
    const isIdStorage = id.includes('/');
    if (isIdStorage && userParser) {
      setLoading(true);
      const deleted = await deleteFile(id, userParser.token);
      if (deleted.success) {
        setDataImages(file => file.filter(f => f.path !== id));
        setTypeMessage('success');
        setMessageAlert(deleted.success);
      } else {
        setTypeMessage('success');
        setMessageAlert(deleted.error ?? 'Não foi possivel remover o arquivo');
      }
      setLoading(false);
      setOpenAlert(true);
    } else {
      const value = uploadFiles.slice().filter(v => v.id !== id);
      setUploadFiles(value);
    }
  };

  const handleUpdateFile = (id: string, value: FilesUpload) => {
    const values = uploadFiles.slice().map(file => {
      return id === file.id ? { ...file, ...value } : file;
    });
    setUploadFiles(values);
  };

  const processUploadFile = async (file: FilesUpload) => {
    const formData = new FormData();
    const img = await imageCompression(file.file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });
    formData.append('file', img, file.name);

    api
      .post(`/order_services_file_gs/${itemGS?.id}`, formData, {
        headers: { Authorization: `Barier ${userParser?.token}` },
        onUploadProgress: (e: ProgressEvent): void => {
          const progress = Math.round(
            (Number(e.loaded) * 100) / Number(e.total),
          );
          handleUpdateFile(file.id, { ...file, progress });
        },
      })
      .then(response => {
        if (response.status === 201) {
          setUploadFiles([]);
          setDataImages(value => [...value, { ...response.data }]);
        }
      })
      .catch(error => {
        handleUpdateFile(file.id, { ...file, error: true });
        const err = error as AxiosError;
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
        setTypeMessage('error');
        setMessageAlert(err.response?.data.message ?? err.message);
        setOpenAlert(true);
      });
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/geometric_shapes"
        >
          Formas Geométricas
        </Link>
        <Typography color="text.primary">{itemGS?.os}</Typography>
      </Breadcrumbs>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table stickyHeader size="small" aria-label="teste">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                Forma geométrica
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell component="td" scope="row">
                Forma
              </TableCell>
              <TableCell component="td" scope="row">
                Altura (m)
              </TableCell>
              <TableCell component="td" scope="row">
                Comprimento (m)
              </TableCell>
              <TableCell component="td" scope="row">
                Largura (m)
              </TableCell>
              <TableCell component="td" scope="row">
                Resultado
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemGS &&
              itemGS.geometricShape.map(gs => (
                <TableRow key={gs.id}>
                  <TableCell component="td" scope="row">
                    {gs.tipo === 1 && 'Triângulo'}
                    {gs.tipo === 2 && 'Retângulo'}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {gs.altura}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {gs.comprimento}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {gs.largura}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3,
                    }).format(gs.result.totalVolumeColetado)}
                    {` `} {itemGS.produto.unidade}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12} md={12} lg={12}>
        <ImgSignatures data={filesGS} />
      </Grid>

      {itemGS && (
        <OrderServiceActions
          dataImages={dataImages}
          densidadeColetada={densidade}
          fatorCompactacaoColetada={fatorCompactacao}
          handleDeleteFile={handleDeleteFile}
          handleSaveCollectedValues={saveCollected}
          handleStatus={handleStatus}
          handleUpload={handleUpload}
          isSaving={loading}
          processUploadFile={processUploadFile}
          setDensidadeColetada={setDensidade}
          setFatorCompactColetada={setFatorCompactacao}
          status={itemGS.status}
          uploadFiles={uploadFiles}
          openAlertSave={openAlertSave}
          setOpenAlertSave={setOpenAlertSave}
          openModalCollected={openModal}
          setOpenModalCollected={setOpenModal}
          justify={justify}
          setJustify={setJustify}
        />
      )}
      <AlertSnackbars
        message={messageAlert}
        type={typeMessage}
        open={openAlert}
        setOpen={setOpenAlert}
      />
    </Container>
  );
};

export default ItemGeometricShape;
