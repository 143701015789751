import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import { FilesVE, FilesOS } from '../interfaces';

const urlAPI = process.env.REACT_APP_URL_API;

interface DataProps {
  data: Array<FilesVE | FilesOS>;
}

const ImgSignatures: React.FC<DataProps> = props => {
  const { data } = props;

  const handlerImage = (path: string) => {
    if (path.includes('s3.amazonaws.com')) return path;
    return `${urlAPI}/files/${encodeURIComponent(path)}`;
  };

  return (
    <ImageList>
      <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div">Assinaturas</ListSubheader>
      </ImageListItem>
      {data.map(
        item =>
          item.type === 'A' && (
            <ImageListItem key={item.id}>
              <img
                src={`${handlerImage(item.path)}?w=248&fit=crop&auto=format`}
                srcSet={`${handlerImage(
                  item.path,
                )}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.name}
                loading="lazy"
              />
              <ImageListItemBar title={item.name} subtitle={item.occupation} />
            </ImageListItem>
          ),
      )}
    </ImageList>
  );
};

export default ImgSignatures;
