import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';

import { OrderServiceProps } from '../../interfaces';
import CardOS from '../../components/CardOS';
import api from '../../services/api';
import useStorageUser from '../../hooks/useStorageUser';

const OrderServices: React.FC = () => {
  const [orderServices, setOrderServices] = React.useState<OrderServiceProps[]>(
    [],
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      let uri = '/order_services';
      if (searchParams.get('cSiloId')) {
        uri = `${uri}?cSiloId=${searchParams.get('cSiloId')}`;
      }
      api
        .get(uri, {
          headers: { Authorization: `Barier ${userParser?.token}` },
        })
        .then(response => {
          setLoading(false);
          setOrderServices(response.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
        });
    }
  }, [searchParams, userParser]);

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
          Silos
        </Link>
        <Typography color="text.primary">Ordem de Serviços</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        {orderServices &&
          orderServices.map(os => <CardOS data={os} key={os.id} />)}
      </Grid>
    </Container>
  );
};

export default OrderServices;
