import * as React from 'react';
import { Box, Paper } from '@mui/material';
import DetailIcon from '@mui/icons-material/RemoveRedEyeSharp';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import ToolbarExportToCSV from '../../../components/ToolbarExportToCSV';

type RowResume = {
  id: number;
  createdTime: string;
  dataContabil: string;
  estoqueTerceiros: number;
  outrasFiliaisTerceiros: number;
  referencia: string;
  totalContabil: number;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'createdTime',
    headerName: 'Criado em',
    type: 'dateTime',
    width: 160,
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: 'referencia',
    headerName: 'Referência',
    width: 100,
  },
  {
    field: 'dataContabil',
    headerName: 'Data contábil',
    type: 'date',
    width: 150,
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: 'estoqueTerceiros',
    headerName: 'Estoque de terceiros (kg)',
    type: 'number',
    width: 150,
  },
  {
    field: 'outrasFiliaisTerceiros',
    headerName: 'Outras filiais (kg)',
    type: 'number',
    width: 150,
  },
  {
    field: 'totalContabil',
    headerName: 'Total contabil (kg)',
    type: 'number',
    width: 160,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const navigate = useNavigate();
      return [
        <GridActionsCellItem
          icon={<DetailIcon />}
          label="Detail"
          className="textPrimary"
          onClick={() => navigate(`/report/resume/${id}`)}
          color="inherit"
        />,
      ];
    },
  },
];

const TableResume: React.FC<{ rows: RowResume[]; loading: boolean }> = ({
  rows,
  loading,
}) => (
  <Box sx={{ height: 520, width: '100%' }} component={Paper} marginTop={2}>
    <DataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      slots={{
        toolbar: () => ToolbarExportToCSV({ name: 'summary-report' }),
      }}
    />
  </Box>
);

export default TableResume;
