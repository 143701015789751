import * as React from 'react';
import {
  Breadcrumbs,
  Container,
  Typography,
  LinearProgress,
  Box,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../../services/api';
import { Resume } from '../../../interfaces';
import FilterReport from '../components/FilterReport';
import TableResume from '../components/TableResume';
import useStorageUser from '../../../hooks/useStorageUser';

const Resumes: React.FC = () => {
  const [listResume, setlistResume] = React.useState<Resume[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [product, setproduct] = React.useState<string>('');
  const [branch, setBranch] = React.useState<string>('');
  const [year, setYear] = React.useState<string>('');
  const [month, setMonth] = React.useState<string>('');

  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    const getReport = async () => {
      if (!userParser || branch === '' || product === '') return;
      setLoading(true);
      const period =
        year !== '' && month !== '' ? `&period=${year}-${month}` : '';

      const params = `?cBranchId=${branch}&products=${product}${period}`;
      try {
        const response = await api.get(`/resume/report${params}`, {
          headers: { Authorization: `Barier ${userParser.token}` },
        });
        if (response.status === 200) {
          setlistResume(response.data);
        }

        setLoading(false);
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    };
    getReport();
  }, [year, month, branch, product]);

  const handleProduct = (value: String) => {
    setproduct(value.toString());
  };

  const handleBranch = (value: String) => {
    setBranch(value.toString());
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/report">
          Relatórios
        </Link>
        <Typography color="text.primary">Resumos</Typography>
      </Breadcrumbs>
      <FilterReport
        branch={branch}
        product={product}
        handleBranch={handleBranch}
        handleProduct={handleProduct}
        setLoading={setLoading}
        handleMonth={setMonth}
        month={month}
        handleYear={setYear}
        year={year}
      />
      <TableResume
        rows={listResume.map(resume => ({
          id: resume.id,
          createdTime: resume.createdTime,
          dataContabil: resume.dataContabil,
          estoqueTerceiros: resume.outrasFiliaisTerceiros,
          outrasFiliaisTerceiros: resume.outrasFiliaisTerceiros,
          referencia: resume.referencia,
          totalContabil: resume.totalContabil,
        }))}
        loading={loading}
      />
    </Container>
  );
};

export default Resumes;
