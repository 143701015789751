import * as React from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const ToolbarExportToCSV: React.FC<{ name: string }> = ({ name }) => (
  <GridToolbarContainer>
    <GridToolbarExport
      csvOptions={{
        fileName: name,
        delimiter: ';',
        utf8WithBom: true,
      }}
    />
  </GridToolbarContainer>
);

export default ToolbarExportToCSV;
