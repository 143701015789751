import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import RoutesApp from './routes';
// import { FaServicestack, FaIndustry, FaAlignJustify } from 'react-icons/fa';
const mdTheme = createTheme();

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <RoutesApp />
    </ThemeProvider>
  );
}

export default App;
