import * as React from 'react';

import {
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const products = [
  { id: 1, description: 'Soja' },
  { id: 2, description: 'Milho' },
  { id: 3, description: 'Farelo' },
  { id: 4, description: 'Casca' },
  { id: 5, description: 'Cavaco' },
  { id: 6, description: 'Lenha' },
  { id: 7, description: 'Óleo' },
];

const SelectProduct: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
          Silos
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Typography color="text.primary">Produtos</Typography>
      </Breadcrumbs>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {products.map(product => (
          <Grid item xs={12} md={4} lg={2} key={product.id}>
            <Card>
              <CardActionArea
                onClick={() =>
                  navigate(
                    `/branches/${params.id}/resume?product_id=${product.id}`,
                  )
                }
              >
                <CardContent>
                  <Typography variant="body2">{product.description}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SelectProduct;
