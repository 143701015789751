/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Breadcrumbs,
  Typography,
  TextField,
  Box,
  Stack,
  Button,
  Fab,
  CircularProgress,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import RejectIcon from '@mui/icons-material/Cancel';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import { AxiosError } from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';

import AlertSaveSuccess from '../../../components/CustomizedSnackbars';
import AlertSaveError from '../../../components/CustomizedSnackbarsError';
import AlertSnackbars from '../../../components/AlertSnackbars';
import CustomModal from '../../../components/CustomModal';
import api from '../../../services/api';
import TableResume from '../../../components/TableResume';
import { ResumeProps } from '../../../interfaces';
import ImgSignatures from '../../../components/ImgSignatures';
import useQuery from '../../../hooks/useQuery';
import useStorageUser from '../../../hooks/useStorageUser';

const PRODUTO_CAVACO = 'LENHA';
const PRODUTO_LENHA = 'CAVACO';

const Resume: React.FC = () => {
  const [resumeInventory, setResumeInventory] = React.useState<ResumeProps>();
  const [openModalContabil, setOpenModalContabil] =
    React.useState<boolean>(false);
  const [modalContabilLenhaCavaco, setModalContabilLenhaCavaco] =
    React.useState<boolean>(false);
  const [txtContabil, setTxtContabil] = React.useState<string>('');
  const [txtOtherBranchParties, setTxtOtherBranchParties] =
    React.useState<string>('');
  const [txtInventoryParties, setTxtInventoryParties] =
    React.useState<string>('');
  const [totalContabil, setTotalContabil] = React.useState<number>(0);
  const [otherBranchParties, setOtherBranchParties] = React.useState<number>(0);
  const [inventoryParties, setInventoryParties] = React.useState<number>(0);
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);
  const [openAlertError, setOpenAlertError] = React.useState<boolean>(false);
  const [openAlertWarning, setOpenAlertWarning] =
    React.useState<boolean>(false);
  const [messageWarning, setMessageWaring] = React.useState<string>('');
  const [messageError, setMessageError] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [referencia, setReferencia] = React.useState<string>('');
  const [dateContabil, setDateContabil] = React.useState<string>('');
  const [isSendEmail, setSendEmail] = React.useState<boolean>(false);
  const [kiloToTon, setKiloToTon] = React.useState<string>('0');
  const [txtJustify, setTxtJustify] = React.useState<string>('');

  const params = useParams();
  const navigate = useNavigate();
  const productId = useQuery().get('product_id');
  const productName = useQuery().get('product');
  const { userParser, validateUserStorage, clearUserStorage } =
    useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    async function getInvetoryValues() {
      if (userParser) {
        setIsLoading(true);
        try {
          const productQuery = productId
            ? `product_id=${productId}`
            : `product=${productName}`;

          const uri = `/resume/inventory_resume?branch_id=${params.id}&${productQuery}`;
          const response = await api.get(uri, {
            headers: { Authorization: `Barier ${userParser.token}` },
          });
          const { data } = response;
          setResumeInventory(data);
          setIsLoading(false);
        } catch (error) {
          const err = error as AxiosError;
          setIsLoading(false);
          setMessageError(
            err.response?.data.message ??
              'Não foi possível carregar das informações. Por favor, tente novamente.',
          );
          if (err.response?.status === 401) {
            setOpenAlertWarning(true);
            setMessageWaring('Faça o login novamente');
            clearUserStorage();
          }
        }
      }
    }

    if (params.id && (productId || productName)) {
      getInvetoryValues();
    }
  }, [params, productId, productName, userParser]);

  React.useEffect(() => {
    if (txtContabil !== '' && txtOtherBranchParties !== '' && referencia !== '')
      setSendEmail(true);
  }, [txtContabil, txtOtherBranchParties, referencia]);

  React.useEffect(() => {
    if (Number(txtContabil) > 0) {
      const contabilToTon = Number(txtContabil) / 1000;
      const contabilFormated = new Intl.NumberFormat('pt-br', {}).format(
        contabilToTon,
      );
      setKiloToTon(contabilFormated);
      if (contabilToTon > 50 || contabilToTon < -50) {
        setOpenAlertWarning(true);
        setMessageWaring('O valor contabil está fora da faixa esperada.');
      }
    }
  }, [txtContabil]);

  const handleClickContabil = (): void => {
    if (productName === PRODUTO_CAVACO || productName === PRODUTO_LENHA) {
      if (
        txtContabil !== '' &&
        txtOtherBranchParties !== '' &&
        referencia !== ''
      ) {
        setOtherBranchParties(Number(txtOtherBranchParties));
        setTotalContabil(Number(txtContabil));
        setModalContabilLenhaCavaco(value => !value);
      }
    } else if (
      txtContabil !== '' &&
      txtOtherBranchParties !== '' &&
      txtInventoryParties !== '' &&
      referencia !== ''
    ) {
      setOtherBranchParties(Number(txtOtherBranchParties));
      setTotalContabil(Number(txtContabil));
      setInventoryParties(Number(txtInventoryParties));
      setOpenModalContabil(value => !value);
    }
  };

  const openFormContabil = (): void => {
    if (productName === PRODUTO_CAVACO || productName === PRODUTO_LENHA) {
      setModalContabilLenhaCavaco(value => !value);
    } else {
      setOpenModalContabil(value => !value);
    }
  };

  const handleClickSaveResume = async (): Promise<void> => {
    setIsLoading(true);
    if (
      txtContabil === '' &&
      txtOtherBranchParties === '' &&
      referencia === ''
    ) {
      setOpenAlertWarning(true);
      setIsLoading(false);
      setOpenModalContabil(value => !value);
      return;
    }

    try {
      const data = {
        cBranchId: params.id,
        order: resumeInventory?.order ?? { ev: [], fw: [], gs: [], os: [] },
        outrasFiliaisTerceiros: otherBranchParties,
        estoqueTerceiros: inventoryParties,
        dataContabil: dateContabil,
        justificativa: txtJustify,
        totalContabil,
        referencia,
      };

      const response = await api.post('/resume', data, {
        headers: { Authorization: `Barier ${userParser?.token}` },
      });

      if (response.status === 201) {
        setOpenAlertSave(true);
        setTotalContabil(0);
        setOtherBranchParties(0);
        setInventoryParties(0);
        setSendEmail(false);
      } else {
        setOpenAlertError(true);
        setMessageError(
          response.data.message ??
            'Não foi possível carregar das informações. Por favor, tente novamente.',
        );
      }
      setIsLoading(false);
    } catch (error) {
      const err = error as AxiosError;
      setMessageError(
        err.response?.data.message ??
          'Não foi possível carregar das informações. Por favor, tente novamente.',
      );
      if (err.response?.status === 401) navigate('/auth/login');
      setOpenAlertError(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
          Silos
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/summary"
        >
          Resumos
        </Link>
        <Typography color="text.primary">Novo Resumo</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        margin={3}
      >
        <LoadingButton
          disabled={isLoading}
          onClick={openFormContabil}
          color="info"
          loadingPosition="start"
          loading={isLoading}
          size="small"
          variant="contained"
          startIcon={<FileCopyIcon />}
        >
          Informações do Contábil
        </LoadingButton>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        marginLeft={1}
        spacing={2}
      >
        {resumeInventory && (
          <TableResume
            dataInventory={resumeInventory}
            totalContabil={totalContabil}
            otherBranchParties={otherBranchParties}
            inventoryParties={inventoryParties}
            dateContabil={null}
            dateResume={new Date().toDateString()}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={6} container>
        <TextField
          fullWidth
          style={{ backgroundColor: 'white' }}
          id="outlined-text"
          label="Justificativa"
          placeholder="Por favor, forneça uma breve justificativa, se necessário."
          type="text"
          multiline
          rows={2}
          inputProps={{ maxLength: 255 }}
          value={txtJustify}
          onChange={txt => setTxtJustify(txt.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <ImgSignatures data={resumeInventory?.files || []} />
      </Grid>
      {isSendEmail && (
        <Fab
          color="primary"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          onClick={handleClickSaveResume}
        >
          {isLoading ? (
            <CircularProgress size={32} color="inherit" />
          ) : (
            <EmailIcon />
          )}
        </Fab>
      )}

      <CustomModal
        widht={400}
        open={openModalContabil}
        setOpen={setOpenModalContabil}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': {
                m: 1,
                width: '35ch',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="outlined-required outlined-number"
              label={`Total Contábil (${
                resumeInventory && resumeInventory.product.unidade
              })`}
              value={txtContabil}
              onChange={txt =>
                setTxtContabil(txt.target.value.replace(/(?!^-)[^0-9.]/g, ''))
              }
              type="text"
            />
            {resumeInventory && resumeInventory.product.unidade === 'kg' && (
              <Box marginLeft={2} style={{ fontSize: 12, color: 'green' }}>
                Convertendo para {kiloToTon} Toneladas
              </Box>
            )}
            <TextField
              required
              id="outlined-required outlined-number"
              label={`Outras Filiais e/ou Terceiros (${
                resumeInventory && resumeInventory.product.unidade
              })`}
              value={txtOtherBranchParties}
              onChange={txt => setTxtOtherBranchParties(txt.target.value)}
              type="number"
            />
            <TextField
              required
              id="outlined-required outlined-number"
              label={`Estoque em poder de terceiros (${
                resumeInventory && resumeInventory.product.unidade
              })`}
              value={txtInventoryParties}
              onChange={txt => setTxtInventoryParties(txt.target.value)}
              type="number"
            />
            <TextField
              required
              id="outlined-required outlined-text"
              label="Referência"
              placeholder="mm/aaaa"
              value={referencia}
              onChange={txt => setReferencia(txt.target.value)}
              type="month"
            />
          </Box>
          <Stack direction="row" spacing={3}>
            <Button
              onClick={handleClickContabil}
              size="large"
              variant="contained"
              startIcon={<SaveIcon />}
            />
            <Button
              onClick={() => setOpenModalContabil(false)}
              variant="contained"
              size="large"
              startIcon={<RejectIcon />}
            />
          </Stack>
        </Grid>
      </CustomModal>

      <CustomModal
        widht={600}
        open={modalContabilLenhaCavaco}
        setOpen={setModalContabilLenhaCavaco}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': {
                m: 1,
                width: '35ch',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="outlined-required outlined-number"
              label={`Contábil de ${
                resumeInventory && resumeInventory.product.descricao
              } ${
                resumeInventory && resumeInventory.product.unidade
              } (saldo anterior)`}
              value={txtContabil}
              onChange={txt =>
                setTxtContabil(txt.target.value.replace(/(?!^-)[^0-9.]/g, ''))
              }
              type="number"
            />
            <TextField
              required
              id="outlined-required outlined-text"
              label="Data (saldo anterior)"
              placeholder="dd/mm/aaaa"
              value={dateContabil}
              onChange={txt => setDateContabil(txt.target.value)}
              type="date"
            />
            <TextField
              required
              id="outlined-required outlined-number"
              label={`Entradas (informação das NF's recebidas) ${
                resumeInventory && resumeInventory.product.unidade
              }`}
              value={txtOtherBranchParties}
              onChange={txt => setTxtOtherBranchParties(txt.target.value)}
              type="number"
            />
            <TextField
              required
              id="outlined-required outlined-text"
              label="Referência"
              placeholder="mm/aaaa"
              value={referencia}
              onChange={txt => setReferencia(txt.target.value)}
              type="month"
            />
          </Box>
          <Stack direction="row" spacing={3}>
            <Button
              onClick={handleClickContabil}
              size="large"
              variant="contained"
              startIcon={<SaveIcon />}
            />
            <Button
              onClick={() => setModalContabilLenhaCavaco(false)}
              variant="contained"
              size="large"
              startIcon={<RejectIcon />}
            />
          </Stack>
        </Grid>
      </CustomModal>

      <AlertSaveSuccess
        open={openAlertSave}
        setOpen={setOpenAlertSave}
        message="Inventário concluído, verfique sua caixa de entrada ou caixa de spam para ver os documentos"
      />
      <AlertSaveError
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={messageError}
      />
      <AlertSnackbars
        open={openAlertWarning}
        setOpen={setOpenAlertWarning}
        message={messageWarning}
        type="warning"
      />
    </>
  );
};

export default Resume;
