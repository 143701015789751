import React from 'react';
import { Box, Container, Grid, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';

import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import Copyright from './components/Copyright';
import useStorageUser from '../../hooks/useStorageUser';

const Main: React.FC = () => {
  const themeMaterial = useTheme();
  const isDesktop = useMediaQuery(themeMaterial.breakpoints.up('sm'));
  const { userParser, validateUserStorage } = useStorageUser();
  const [open, setOpen] = React.useState(!isDesktop);
  const toggleDrawer = () => setOpen(!open);

  React.useEffect(() => validateUserStorage(), [userParser]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar toggleDrawer={toggleDrawer} open={open} />
      {userParser && (
        <Drawer
          toggleDrawer={toggleDrawer}
          open={open}
          userParser={userParser}
        />
      )}
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Outlet />
          </Grid>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
};

export default Main;
