import * as React from 'react';
import {
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

type ReportItemType = {
  name: string;
  link: string;
};

const reportItems: Array<ReportItemType> = [
  {
    name: 'Resumos',
    link: 'resumes',
  },
  {
    name: 'Físico x Contabil',
    link: 'phisical_accounting',
  },
  {
    name: 'Rejeições',
    link: 'rejections',
  },
  {
    name: 'Relatório Consolidado',
    link: 'consolidated',
  },
  {
    name: 'Subordinados da controladoria',
    link: 'controlling_subordinates',
  },
  {
    name: 'users',
    link: 'users',
  },
];

const Report: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Typography color="text.primary">Reports</Typography>
      </Breadcrumbs>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {reportItems.map(item => (
          <Grid item xs={12} md={6} lg={3} key={item.link}>
            <Card>
              <CardActionArea onClick={() => navigate(item.link)}>
                <CardContent>
                  <Typography variant="body2" align="center" height={32}>
                    {item.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Report;
