import * as React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

interface CustomModalProps {
  open: boolean;
  setOpen: Function;
  widht: number;
}

const CustomModal: React.FC<CustomModalProps> = props => {
  const { open, setOpen, children, widht } = props;
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: widht,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default CustomModal;
