/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, Typography } from '@mui/material';

const Copyright: React.FC = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      marginTop={10}
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Ryatec Sistemas
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
};

export default Copyright;
