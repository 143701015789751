import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';

type BranchSumary = {
  produto: string;
  descricao: string;
  id: number | undefined;
  regiao: string;
  os: Array<string>;
};

const CardItem: React.FC<BranchSumary> = ({
  produto,
  descricao,
  id,
  regiao,
  os,
}) => {
  const navigate = useNavigate();
  return (
    <Grid item xs={6} md={4} lg={3}>
      <Card sx={{ height: 250 }}>
        <CardActionArea
          onClick={() => navigate(`/branches/${id}/resume?product=${produto}`)}
        >
          <CardHeader
            title={produto}
            titleTypographyProps={{ fontSize: 17, fontWeight: 'bold' }}
          />
          <CardContent>
            <Typography component="div">{descricao}</Typography>
            <Typography variant="body2">{regiao}</Typography>
          </CardContent>
          <CardContent>
            {os.map(item => (
              <Typography variant="caption" component="div" key={item}>
                {item}
              </Typography>
            ))}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default CardItem;
