import * as React from 'react';

import {
  CardActionArea,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

import { OrderServiceProps } from '../interfaces';
import deltaDate from '../utils/deltaDate';

interface CardOSProps {
  data: OrderServiceProps;
}

const CardOS: React.FC<CardOSProps> = props => {
  const { data } = props;
  const { categoria } = data.silo;
  const navigate = useNavigate();

  const daysCreated = deltaDate(data.dataCriacao, new Date());

  let colorCard = '#fff';
  let typeOS = 'appointment_v';

  if (data.status === 'APROVADO') {
    colorCard = '#ccf0ae';
  } else if (data.status === 'REJEITADO') {
    colorCard = '#eca0a0';
  }

  switch (categoria.type) {
    case 'silo':
      typeOS = 'appointment_mc';
      break;
    case 'armazem':
      typeOS = 'appointment_v';
      break;
    case 'caixa':
      typeOS = 'appointment_box';
      break;
    case 'oil':
      typeOS = 'appointment_mc';
      break;
    case 'tank':
      typeOS = 'appointment_mc';
      break;
    case 'bunker':
      typeOS = 'appointment_v';
      break;
    default:
      typeOS = 'appointment_v';
      break;
  }
  return (
    <Grid item xs={12} md={6} lg={3}>
      <Card
        sx={{
          bgcolor: colorCard,
        }}
      >
        <CardActionArea
          onClick={() => navigate(`/order_services/${typeOS}/${data.id}`)}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Criado em: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.dataCriacao))}`}{' '}
              {daysCreated > 29 && (
                <Icon
                  sx={{
                    color: data.status === 'REJEITADO' ? 'yellow' : 'orange',
                  }}
                >
                  warning
                </Icon>
              )}
            </Typography>
            <Typography color="text.secondary">
              {data.silo.descricao}
            </Typography>
            <Typography color="text.secondary">{categoria.type}</Typography>
            <Typography
              sx={{ mb: 1.2, fontSize: 9 }}
              variant="body2"
              color="text.secondary"
            >
              {data.silo.branch.descricao}
            </Typography>
            <Typography variant="h6" component="div">
              OS: {data.os}
            </Typography>
            <Typography variant="body2">{data.produto.descricao}</Typography>
          </CardContent>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {data.matricula}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {`Sincronização: ${Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(data.dataSincronizacao))}`}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default CardOS;
