import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import RejectIcon from '@mui/icons-material/Cancel';
import { AxiosError } from 'axios';

import api from '../../../services/api';
import CustomModal from '../../../components/CustomModal';
import { Branch } from '../../../interfaces';
import BranchPermitions from '../../Permitions/components/BranchPermitions';
import useStorageUser from '../../../hooks/useStorageUser';

interface EmailsProps {
  id: number;
  email: string;
  branches?: Array<Branch>;
}

const Emails: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [listMail, setListMail] = React.useState<Array<EmailsProps>>();
  const [email, setEmail] = React.useState<string>('');
  const [openModalNewMail, setOpenModalNewMail] =
    React.useState<boolean>(false);
  const [branchesUserPermitons, setBranchesUserPermitions] =
    React.useState<Branch[]>();
  const [checkedPermitions, setCheckedPermitions] = React.useState<
    Array<Branch>
  >([]);
  const [openModaPermition, setOpenModalPermition] =
    React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [emailSelected, setEmailSelected] = React.useState<EmailsProps>();

  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (!userParser) return;
    setLoading(true);
    api
      .get('/emails', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => {
        setListMail(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    api
      .get('/branches', {
        headers: { Authorization: `Barier ${userParser.token}` },
      })
      .then(response => {
        setSaving(false);
        setBranchesUserPermitions(response.data);
      })
      .catch(error => {
        const err = error as AxiosError;
        setSaving(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      });
  }, [userParser]);

  const handleOpenModalPermition = (data: EmailsProps) => {
    setEmailSelected(data);
    setCheckedPermitions(data.branches ? data.branches : []);
    setOpenModalPermition(true);
  };

  const handleSavePermitions = async () => {
    // FIXEME não selecionar as permissões já existentes
    if (emailSelected && userParser) {
      setSaving(true);
      const data = checkedPermitions.map(cp => {
        return { lEmailId: emailSelected.id, cBranchId: cp.id };
      });
      try {
        const response = await api.post(
          `/email/branches/${emailSelected.id}`,
          { data },
          {
            headers: { Authorization: `Barier ${userParser.token}` },
          },
        );
        if (response.status === 201) {
          // Update permitions user list
          const emailUpadate = listMail?.map(emailValue => {
            if (emailValue.id === emailSelected.id && emailValue.branches) {
              return {
                ...emailValue,
                branches: checkedPermitions,
              };
            }
            return emailValue;
          });
          setListMail(emailUpadate);
          setEmailSelected(undefined);
          setCheckedPermitions([]);
          setSaving(false);
          setOpenModalPermition(false);
        }
      } catch (error) {
        const err = error as AxiosError;
        setSaving(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    }
  };

  const saveMail = async () => {
    setLoading(true);
    if (email && userParser) {
      try {
        const response = await api.post(
          '/email',
          { email },
          { headers: { Authorization: `Barier ${userParser.token}` } },
        );
        if (response.status === 201) {
          setListMail(value => (value ? [...value, response.data] : value));
          setOpenModalNewMail(false);
          setEmail('');
        }
        setLoading(false);
      } catch (error) {
        // alert('Digite um email válido');
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/settings"
        >
          configurações
        </Link>
        <Typography color="text.primary">emails</Typography>
      </Breadcrumbs>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Grid item md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Email</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listMail &&
                    listMail.map(data => (
                      <TableRow key={data.id}>
                        <TableCell>{data.email}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="permitions"
                            size="small"
                            style={{ marginRight: 20 }}
                            onClick={() => handleOpenModalPermition(data)}
                          >
                            <LockOpenIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>

      {(userParser?.lCategory.name === 'owner' ||
        userParser?.lCategory.name === 'admin') && (
        <Fab
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          color="primary"
          aria-label="add"
          onClick={() => setOpenModalNewMail(true)}
        >
          <AddIcon />
        </Fab>
      )}

      <CustomModal
        widht={600}
        open={openModalNewMail}
        setOpen={setOpenModalNewMail}
      >
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              required
              id="outlined-required"
              label="email"
              type="email"
              value={email}
              onChange={txt => setEmail(txt.target.value)}
            />
          </div>
        </Box>
        <Stack direction="row" spacing={3} margin={2} justifyContent="center">
          <LoadingButton
            loadingPosition="start"
            loading={loading}
            size="large"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={saveMail}
          >
            Salvar
          </LoadingButton>
        </Stack>
      </CustomModal>

      <CustomModal
        widht={600}
        open={openModaPermition}
        setOpen={setOpenModalPermition}
      >
        {branchesUserPermitons && emailSelected && (
          <BranchPermitions
            branches={branchesUserPermitons}
            userBranches={
              emailSelected.branches ? emailSelected.branches : null
            }
            checkedPermitions={checkedPermitions}
            setCheckedPermitions={setCheckedPermitions}
            userCategory={userParser?.lCategory.name ?? ''}
          />
        )}
        <Stack direction="row" spacing={3} margin={2} justifyContent="center">
          {(userParser?.lCategory.name === 'owner' ||
            userParser?.lCategory.name === 'admin') && (
            <LoadingButton
              disabled={saving}
              loadingPosition="start"
              loading={saving}
              size="large"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSavePermitions}
            />
          )}
          <Button
            onClick={() => setOpenModalPermition(false)}
            variant="contained"
            size="large"
            startIcon={<RejectIcon />}
          />
        </Stack>
      </CustomModal>
    </Container>
  );
};

export default Emails;
