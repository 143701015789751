/* eslint-disable no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../../services/api';
import { Branch } from '../../../interfaces';
import { ProductType } from '../../../types';

const FilterPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 120,
  display: 'flex',
  alignContent: 'center',
  justifyItems: 'center',
  alignItems: 'center',
}));

type FilterProps = {
  setLoading: Function;
  handleBranch: (value: String) => void;
  branch: String;
  handleProduct: (value: String) => void;
  product: String;
  handleYear: (value: string) => void;
  year: string;
  handleMonth: (value: string) => void;
  month: string;
};

interface MonthObject {
  month: string;
  number: string;
}

function createYearArray(): number[] {
  const currentYear: number = new Date().getFullYear();
  const years: number[] = [];

  // eslint-disable-next-line no-plusplus
  for (let year: number = 2020; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}

function createMonthArray(): MonthObject[] {
  const months: MonthObject[] = [];
  const monthNames: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  // eslint-disable-next-line no-plusplus
  for (let i: number = 0; i < 12; i++) {
    const monthNumber: number = i + 1;
    const monthObject: MonthObject = {
      month: monthNames[i],
      number: monthNumber < 10 ? `0${monthNumber}` : monthNumber.toString(),
    };
    months.push(monthObject);
  }

  return months;
}

const FilterReport: React.FC<FilterProps> = props => {
  const {
    setLoading,
    handleBranch,
    branch,
    handleProduct,
    product,
    handleMonth,
    month,
    handleYear,
    year,
  } = props;
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [products, setProducts] = React.useState<ProductType[]>([]);
  const navigate = useNavigate();

  const userStorage = localStorage.getItem('@storage_user');
  if (userStorage === null) {
    navigate('/auth/login');
  }
  const userParser = userStorage && JSON.parse(userStorage);

  React.useEffect(() => {
    async function getBranches() {
      try {
        const [responseBranch, responseProduct] = await Promise.all([
          api.get(`/branches`, {
            headers: { Authorization: `Barier ${userParser?.token}` },
          }),
          api.get(`/products`, {
            headers: { Authorization: `Barier ${userParser?.token}` },
          }),
        ]);
        if (responseBranch.status === 200) setBranches(responseBranch.data);

        if (responseProduct.status === 200) setProducts(responseProduct.data);

        setLoading(false);
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    }
    getBranches();
  }, []);

  return (
    <Grid container spacing={2} paddingTop={2}>
      <Grid item xs={12} md={12} sm={6}>
        <FilterPaper elevation={1}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="branch-label">Filial</InputLabel>
            <Select
              labelId="branch-label"
              id="branch"
              value={branch}
              onChange={event => handleBranch(event.target.value)}
            >
              {branches.map(branchItem => (
                <MenuItem key={branchItem.id} value={branchItem.id}>
                  {branchItem.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="product-label">Produto</InputLabel>
            <Select
              labelId="product-label"
              id="product"
              value={product}
              onChange={event => handleProduct(event.target.value)}
            >
              {products.map(productItem => (
                <MenuItem key={productItem.id} value={productItem.id}>
                  {productItem.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="year-label">Período ano</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              onChange={event => handleYear(event.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              {Array.from(createYearArray()).map((y, index) => (
                <MenuItem key={Number(index)} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="month-label">Período mês</InputLabel>
            <Select
              labelId="month-label"
              id="month"
              value={month}
              onChange={event => handleMonth(event.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              {createMonthArray().map(m => (
                <MenuItem key={m.number} value={m.number}>
                  {m.month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FilterPaper>
      </Grid>
    </Grid>
  );
};

export default FilterReport;
