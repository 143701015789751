import * as React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

interface CardTotalVProps {
  title: string;
  value: number | undefined;
  widthLG: number;
  isAppointment: boolean;
}

const CardTotalV: React.FC<CardTotalVProps> = props => {
  const { title, value, widthLG, isAppointment } = props;
  return (
    <Grid item xs={6} md={widthLG === 4 ? 6 : 12} lg={widthLG}>
      <Card style={{ background: isAppointment ? 'lightyellow' : 'white' }}>
        <CardContent>
          <Typography variant="body2">{title}</Typography>
          <Typography variant="h5" component="div">
            {Intl.NumberFormat('pt-BR', { maximumFractionDigits: 3 }).format(
              Number(value),
            )}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardTotalV;
