import * as React from 'react';
import { Grid } from '@mui/material';
import CardTotalV from '../../../../components/CardTotalV';
import { MCAppointment } from '../../../../interfaces';
import TableAppoitmentMC from '../../../../components/TableAppoitmentMC';

type GenericProps = {
  orderService: MCAppointment;
};

/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow, no-unused-vars
enum SiloCategoryEnum {
  PULMAO = 5,
}

const Generic: React.FC<GenericProps> = ({ orderService }) => (
  <>
    <Grid container direction="row" spacing={1} marginLeft={3}>
      <CardTotalV
        isAppointment={false}
        widthLG={2}
        title="A"
        value={Number(orderService.silo.larguraA)}
      />
      <CardTotalV
        isAppointment={false}
        widthLG={2}
        title="B"
        value={Number(orderService.silo.alturaCoberturaB)}
      />
      {orderService.silo.categoria.id === SiloCategoryEnum.PULMAO && (
        <>
          <CardTotalV
            isAppointment={false}
            widthLG={2}
            title="C"
            value={Number(orderService.silo.alturaParedeC)}
          />
          <CardTotalV
            isAppointment={false}
            widthLG={2}
            title="D"
            value={Number(orderService.silo.alturaTaludeD)}
          />
        </>
      )}
      <CardTotalV
        isAppointment
        widthLG={2}
        title="Lateral"
        value={orderService.mcApontamentos?.lateral}
      />
      <CardTotalV
        isAppointment
        widthLG={2}
        title="Centro"
        value={orderService.mcApontamentos?.centro}
      />
      {orderService.silo.categoria.id === SiloCategoryEnum.PULMAO && (
        <CardTotalV
          isAppointment
          widthLG={2}
          title="Altura do talude"
          value={orderService.mcApontamentos?.alturaTalude}
        />
      )}
    </Grid>

    <Grid container direction="row" spacing={1} marginLeft={3} marginTop={2}>
      <CardTotalV
        isAppointment={false}
        widthLG={3}
        title="Densidade"
        value={orderService.densidadeColetada}
      />
      <CardTotalV
        isAppointment={false}
        widthLG={3}
        title="Fator de compactação"
        value={orderService.fatorCompactacaoColetada}
      />
      <TableAppoitmentMC
        armazem={orderService.armazem}
        mcApontamentos={orderService.mcApontamentos}
        silo={orderService.silo}
        produto={orderService.produto}
      />
    </Grid>
  </>
);

export default Generic;
