import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';

import { Branch } from '../interfaces';

interface BranchProps {
  data: Branch;
}

const CardBranches: React.FC<BranchProps> = props => {
  const { data } = props;
  const navigate = useNavigate();
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card sx={{ minHeight: 150 }}>
        <CardActionArea onClick={() => navigate(`/summary`)}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {data.id}
            </Typography>
            <Typography variant="h6" component="div">
              {data.descricao}
            </Typography>
            <Typography variant="body2">{data.regiao}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button size="small" onClick={() => navigate(`/branches/${data.id}`)}>
            Silos
          </Button>
          <Button
            size="small"
            onClick={() => navigate(`/branches/${data.id}/volume_extra`)}
          >
            Volumes extras
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardBranches;
