import * as React from 'react';
import { Box, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import UserActiveIcon from '@mui/icons-material/CheckCircle';
import UserDeactivateIcon from '@mui/icons-material/CancelOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useNavigate } from 'react-router-dom';

import ToolbarExportToCSV from '../../../components/ToolbarExportToCSV';
import { Users } from '../../../interfaces';
// eslint-disable-next-line no-unused-vars
import deltaDate from '../../../utils/deltaDate';

interface TableusersProps {
  rows: Users[];
  handlePermition: Function;
  loading: boolean;
}

const Tableusers: React.FC<TableusersProps> = ({
  rows,
  handlePermition,
  loading,
}) => {
  const navigate = useNavigate();
  const columns: GridColDef<Users>[] = [
    { field: 'username', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Nome',
      width: 180,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
    },
    {
      field: 'lCategory',
      headerName: 'Categoria',
      width: 100,
      valueGetter: params => params.row?.lCategory.name,
    },
    {
      field: 'createdAt',
      headerName: 'Criado em',
      type: 'dateTime',
      width: 160,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'updatedAt',
      headerName: 'Atualizado em',
      type: 'dateTime',
      width: 160,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'lastLogin',
      headerName: 'Último login em',
      type: 'dateTime',
      width: 160,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'diffDays',
      type: 'number',
      headerName: 'Dias sem acesso',
      align: 'center',
      width: 180,
      valueGetter: ({ row }) =>
        deltaDate(new Date(row.lastLogin ?? row.createdAt), new Date()),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) =>
        value ? (
          <UserActiveIcon color="success" />
        ) : (
          <UserDeactivateIcon color="warning" />
        ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Permissões',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<LockOpenIcon />}
            label="Detail"
            className="textPrimary"
            onClick={() => handlePermition(row)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: 'view',
      type: 'actions',
      headerName: 'Editar',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Detail"
            className="textPrimary"
            onClick={() => navigate(`/auth/${row.username}`)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ height: 520, width: '100%' }} component={Paper} marginTop={2}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        disableRowSelectionOnClick
        slots={{
          toolbar: () => ToolbarExportToCSV({ name: 'summary-report' }),
        }}
      />
    </Box>
  );
};

export default Tableusers;
