/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { VApontamento } from '../interfaces';

interface MeasuringPointTableProps {
  vApontamentos: Array<VApontamento>;
  measuringPoint: number;
  type: string;
  title: string;
}

function selectValeuPointMeasuring(type: string, value: VApontamento): number {
  const valueCenter = value.medidaBCDCentral;
  const valueLeft = value.medidaCDEsquerdo;
  const valueRight = value.medidaCDDireito;
  if (type === 'left') {
    return valueLeft;
  }
  if (type === 'center') {
    return valueCenter;
  }
  if (type === 'right') {
    return valueRight;
  }
  return 0;
}

function slopeDiscount(type: string, value: VApontamento): number {
  const valueLeft = value.descontoTaludeLateralEsquerdo;
  const valueRight = value.descontoTaludeLateralDireito;
  if (type === 'left') {
    return valueLeft;
  }
  if (type === 'right') {
    return valueRight;
  }
  return 0;
}

function displayTitle(type: string): string {
  if (type === 'left') {
    return 'Esquerdo';
  }
  if (type === 'center') {
    return 'Centro';
  }
  if (type === 'right') {
    return 'Direito';
  }
  return '';
}

const MeasuringPointTable: React.FC<MeasuringPointTableProps> = props => {
  const { vApontamentos, measuringPoint, title, type } = props;
  return (
    <Grid item xs={12} md={6} lg={4}>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small" aria-label={title}>
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                Ponto de medição {displayTitle(type)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                {Intl.NumberFormat('pt-BR', {
                  maximumFractionDigits: 2,
                }).format(measuringPoint)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Oitão</TableCell>
              <TableCell align="center">Medida do espaço de ar</TableCell>
              <TableCell align="center">Cálculos</TableCell>
              <TableCell align="center">Desconto Talude Lateral</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                {title}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vApontamentos.map(row => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.oitao}
                </TableCell>
                <TableCell align="center">
                  {Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(selectValeuPointMeasuring(type, row))}
                </TableCell>
                <TableCell align="center">
                  {Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    measuringPoint - selectValeuPointMeasuring(type, row),
                  )}
                </TableCell>
                <TableCell align="center">
                  {Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(slopeDiscount(type, row))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default MeasuringPointTable;
