import * as React from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Box,
  Fab,
  LinearProgress,
  Breadcrumbs,
  Container,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import Reverse from '@mui/icons-material/History';
import { AxiosError } from 'axios';

import api from '../../../../services/api';
import TableResume from '../../../../components/TableResume';
import { ResumeProps } from '../../../../interfaces';
import AlertSaveSuccess from '../../../../components/CustomizedSnackbars';
import AlertSaveError from '../../../../components/CustomizedSnackbarsError';
import ImgSignatures from '../../../../components/ImgSignatures';
import useStorageUser from '../../../../hooks/useStorageUser';

const Resume: React.FC = () => {
  const [resumeInventory, setResumeInventory] = React.useState<ResumeProps>();
  const [openAlertSave, setOpenAlertSave] = React.useState<boolean>(false);
  const [openAlertError, setOpenAlertError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingSendMail, setLoadingSendMail] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [openReverse, setOpenReverse] = React.useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    async function getOrderService() {
      if (userParser) {
        setLoading(true);
        try {
          const uri = `/resume/${params.id}/report`;
          const response = await api.get(uri, {
            headers: { Authorization: `Barier ${userParser.token}` },
          });
          const { data, status } = response;

          if (status === 200) setResumeInventory(data);

          setResumeInventory(data);
          setLoading(false);
        } catch (error) {
          const err = error as AxiosError;
          setLoading(false);
          if (err.response?.status === 401) {
            // eslint-disable-next-line no-alert
            alert('Faça o login novamente');
            navigate('/auth/login');
          }
        }
      }
    }

    if (params.id) {
      getOrderService();
    }
  }, [params, userParser]);

  const sendMail = async () => {
    if (loadingSendMail) return;
    setLoadingSendMail(true);
    try {
      const response = await api.get(`/resume/resend_email/${params.id}`, {
        headers: { Authorization: `Barier ${userParser?.token}` },
      });
      if (response.status === 200) {
        setMessage(
          'Inventário concluído, verfique sua caixa de entrada ou caixa de spam para ver os documentos',
        );
        setOpenAlertSave(true);
      } else {
        setMessage(
          'Estamos com dificuldades para finalizar a operação, favor tente mais tarde ou entre em contato com o suporte',
        );
        setOpenAlertError(true);
      }
      setLoadingSendMail(false);
    } catch (error) {
      const errorAxio = error as unknown as AxiosError;
      setMessage(errorAxio.response?.data.message ?? errorAxio.message);
      setLoadingSendMail(false);
      setOpenAlertError(true);
    }
  };

  const reverseServiceOrder = async () => {
    setOpenReverse(false);
    if (loadingSendMail) return;
    setLoadingSendMail(true);
    try {
      const response = await api.put(
        `/resume/reverse/${params.id}`,
        {},
        {
          headers: { Authorization: `Barier ${userParser?.token}` },
        },
      );
      if (response.status === 200) {
        setMessage(response.data.message);
        setOpenAlertSave(true);
      } else {
        setMessage(
          'Não foi possivel complentar a operação, favor tente mais tarde',
        );
        setOpenAlertError(true);
      }
      setLoadingSendMail(false);
    } catch (error) {
      const errorAxio = error as unknown as AxiosError;
      setMessage(errorAxio.response?.data.message ?? errorAxio.message);
      setLoadingSendMail(false);
      setOpenAlertError(true);
    }
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/report">
          Relatórios
        </Link>
        <Typography color="text.primary">Resumos</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop={2}
      >
        {resumeInventory && (
          <TableResume
            dataInventory={resumeInventory}
            totalContabil={resumeInventory.resume?.totalContabil || 0}
            otherBranchParties={
              resumeInventory.resume?.outrasFiliaisTerceiros || 0
            }
            inventoryParties={resumeInventory.resume?.estoqueTerceiros || 0}
            dateContabil={resumeInventory.resume?.dataContabil || ''}
            dateResume={resumeInventory.resume?.createdTime || ''}
          />
        )}
      </Grid>
      {resumeInventory && resumeInventory.resume?.justificativa && (
        <Grid container marginTop={2}>
          <Typography color="text.secondary" fontSize={15} component="pre">
            <strong>Justificativa: </strong>
            {resumeInventory.resume.justificativa}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12}>
        <ImgSignatures data={resumeInventory?.files || []} />
      </Grid>
      <Fab
        color="primary"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        onClick={sendMail}
      >
        {loadingSendMail ? (
          <CircularProgress size={32} color="inherit" />
        ) : (
          <MailIcon />
        )}
      </Fab>
      {(userParser?.lCategory.name === 'owner' ||
        userParser?.lCategory.name === 'admin') && (
        <Fab
          color="warning"
          sx={{ position: 'absolute', bottom: 16, left: 250 }}
          onClick={() => setOpenReverse(true)}
        >
          {loadingSendMail ? (
            <CircularProgress size={32} color="inherit" />
          ) : (
            <Reverse />
          )}
        </Fab>
      )}
      <AlertSaveSuccess
        open={openAlertSave}
        setOpen={setOpenAlertSave}
        message={message}
      />
      <AlertSaveError
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={message}
      />

      <Dialog
        open={openReverse}
        onClose={() => setOpenReverse(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Você Tem Certeza Que Deseja Estornar as Ordens de Serviço Associadas a
          Este Resumo?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            É importante lembrar que esta ação é irreversível. Uma vez que as
            ordens de serviço forem estornadas, não será possível recuperar
            essas informações.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReverse(false)}>Não</Button>
          <Button onClick={reverseServiceOrder} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Resume;
