import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './layouts/Main';
import Branches from './views/Branches';
import FormNewSilo from './views/Silos/FormNewSilo';
import NotFound from './views/NotFound';
import OrderServices from './views/OrderServices';
import Silos from './views/Silos';
import AppointmentV from './views/OrderServices/AppointmentV';
import AppointmentMC from './views/OrderServices/AppointmentMC';
import AppointmentBox from './views/OrderServices/AppointmentBox';
import Resume from './views/Branches/Resume';
import NewBranch from './views/Branches/NewBranch';
import Report from './views/Report';
import ResumeReport from './views/Report/Resumes/Resume';
import ResumesReport from './views/Report/Resumes';
import PhisicalAccountingReport from './views/Report/PhisicalVsAccounting';
import VolumesExtras from './views/VolumesExtras';
import SelectProduct from './views/Branches/Resume/SelectProduct';
import Login from './views/Login';
import Permitions from './views/Permitions';
import FormUser from './views/Permitions/FormUser';
import Settings from './views/Settings';
import Emails from './views/Settings/Emails';
import Products from './views/Settings/Products';
import ItemExtraVolumes from './views/Settings/ItemExtraVolumes';
import OrderServiceExtraVolume from './views/OrderServiceExtraVolume';
import Summary from './views/Summary';
import GeometricShape from './views/GeometricShape';
import ItemGeometricShape from './views/GeometricShape/ItemGeometricShape';
import Firewoods from './views/Firewoods';
import Details from './views/Firewoods/Details';
import Rejections from './views/Report/Rejections';
import Consolidated from './views/Report/Consolidated';
import ControllingSubordinates from './views/Report/ControllingSubordinates';
import Users from './views/Report/Users';

const RoutesApp: React.FC = () => {
  // TODO implement routes private
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/" element={<Main />}>
          <Route index element={<Silos />} />
          <Route path="silo/new" element={<FormNewSilo />} />
          <Route path="silo/update/:id" element={<FormNewSilo />} />

          <Route path="extra_volumes">
            <Route index element={<OrderServiceExtraVolume />} />
            <Route path=":id" element={<VolumesExtras />} />
          </Route>

          <Route path="geometric_shapes">
            <Route index element={<GeometricShape />} />
            <Route path=":id" element={<ItemGeometricShape />} />
          </Route>

          <Route path="lenha">
            <Route index element={<Firewoods />} />
            <Route path=":id" element={<Details />} />
          </Route>

          <Route path="order_services">
            <Route index element={<OrderServices />} />
            <Route path="appointment_v/:id" element={<AppointmentV />} />
            <Route path="appointment_mc/:id" element={<AppointmentMC />} />
            <Route path="appointment_box/:id" element={<AppointmentBox />} />
          </Route>

          <Route path="branches">
            <Route index element={<Branches />} />
            <Route path="new" element={<NewBranch />} />
            <Route path=":id" element={<Silos />} />
            <Route path=":id/select_product" element={<SelectProduct />} />
            <Route path=":id/resume" element={<Resume />} />
            <Route
              path=":id/volume_extra"
              element={<OrderServiceExtraVolume />}
            />
          </Route>

          <Route path="summary">
            <Route index element={<Summary />} />
          </Route>

          <Route path="report">
            <Route index element={<Report />} />
            <Route path="resumes" element={<ResumesReport />} />
            <Route path="resume/:id" element={<ResumeReport />} />
            <Route
              path="phisical_accounting"
              element={<PhisicalAccountingReport />}
            />
            <Route path="rejections" element={<Rejections />} />
            <Route path="consolidated" element={<Consolidated />} />
            <Route
              path="controlling_subordinates"
              element={<ControllingSubordinates />}
            />
            <Route path="users" element={<Users />} />
          </Route>

          <Route path="auth">
            <Route path="users" element={<Permitions />} />
            <Route path="new" element={<FormUser />} />
            <Route path=":username" element={<FormUser />} />
          </Route>

          <Route path="settings">
            <Route index element={<Settings />} />
            <Route path="emails" element={<Emails />} />
            <Route path="products" element={<Products />} />
            <Route path="item_extra_volumes" element={<ItemExtraVolumes />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp;
