/* eslint-disable no-unused-vars */
import { AxiosError } from 'axios';

import api from '../services/api';

type StrageType = {
  deleteFile: (
    path: string,
    token: string,
  ) => Promise<{ error: string | null; success: string | null }>;
};

const useDeleteStoredFile = (): StrageType => {
  const deleteFile = async (path: string, token: string) => {
    try {
      const response = await api.delete(`/files/${encodeURIComponent(path)}`, {
        headers: { Authorization: `Barier ${token}` },
      });
      return { success: response.data.message, error: null };
    } catch (error) {
      const err = error as AxiosError;
      return {
        error: err.response?.data.message ?? err.message,
        success: null,
      };
    }
  };
  return { deleteFile };
};

export default useDeleteStoredFile;
