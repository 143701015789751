import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import ArrowbackIcon from '@mui/icons-material/ArrowBackSharp';
import PasswordIcon from '@mui/icons-material/LockClock';
import { AxiosError } from 'axios';

import api from '../../../services/api';
import useStorageUser from '../../../hooks/useStorageUser';
import { Category, Users } from '../../../interfaces';
import AlertSnackbars from '../../../components/AlertSnackbars';

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NewUser: React.FC = () => {
  const [user, setUser] = React.useState<Users>();
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [username, setUsername] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [category, setCategory] = React.useState<string>('');
  const [jiraId, setjiraId] = React.useState<string>('');
  const [status, setStatus] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [messageAlert, setMessageAlert] = React.useState<string>('');
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [typeMessage, setTypeMessage] = React.useState<AlertColor>('success');
  const [openDialogReset, setOpenDialogReset] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const params = useParams();

  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    async function getUser(userId: string, token: string) {
      setLoading(true);
      try {
        const response = await api.get<Users>(`/user/${userId}`, {
          headers: { Authorization: `Barier ${token}` },
        });
        setUser(response.data);
        setUsername(response.data.username);
        setName(response.data.name);
        setEmail(response.data.email);
        setCategory(String(response.data.lCategory.categoryId));
        setjiraId(response.data.jiraAccount);
        setStatus(response.data.status);
        setLoading(false);
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    }
    async function getCategoies(token: string) {
      setLoading(true);
      try {
        const response = await api.get<Category[]>(`/user/categories`, {
          headers: { Authorization: `Barier ${token}` },
        });
        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        const err = error as AxiosError;
        setLoading(false);
        if (err.response?.status === 401) {
          navigate('/auth/login');
        }
      }
    }

    if (!userParser) return;
    getCategoies(userParser.token);
    if (params && params.username) getUser(params.username, userParser.token);
  }, [params, userParser]);

  const updateUser = async () => {
    if (!userParser || !user || loading) return;
    setLoading(true);
    try {
      await api.put(
        `/user/${user.username}`,
        {
          username,
          name,
          email,
          lCategoryId: category,
          jiraAccount: jiraId,
          status,
        },
        {
          headers: { Authorization: `Barier ${userParser.token}` },
        },
      );
      setLoading(false);
    } catch (error) {
      const err = error as AxiosError;
      setTypeMessage('error');
      setMessageAlert(err.response?.data.message ?? err.message);
      setOpenAlert(true);

      setLoading(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  const resetPassword = async () => {
    setOpenDialogReset(false);
    if (!userParser || !user || loading) return;
    setLoading(true);
    try {
      await api.put(
        `/auth/reset/${user.username}`,
        {},
        {
          headers: { Authorization: `Barier ${userParser.token}` },
        },
      );
      setLoading(false);
    } catch (error) {
      const err = error as AxiosError;
      setTypeMessage('error');
      setMessageAlert(err.response?.data.message ?? err.message);
      setOpenAlert(true);
      setLoading(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  const saveUser = async () => {
    if (!userParser || loading) return;
    setLoading(true);
    try {
      await api.post(
        '/auth/user',
        { username, name, email, lCategoryId: category, jiraAccount: jiraId },
        {
          headers: { Authorization: `Barier ${userParser.token}` },
        },
      );
      setLoading(false);
      navigate('/auth/users');
    } catch (error) {
      const err = error as AxiosError;
      setTypeMessage('error');
      setMessageAlert(err.response?.data.message ?? err.message);
      setOpenAlert(true);
      setLoading(false);
      if (err.response?.status === 401) {
        navigate('/auth/login');
      }
    }
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ color: 'inherit', textDecoration: 'none', marginLeft: 10 }}
          to="/"
        >
          Silos
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none', marginLeft: 10 }}
          to="/auth/users"
        >
          Usuários
        </Link>
        <Typography color="text.primary">Usuário</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop={2}
      >
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              required
              id="outlined-required"
              label="ID"
              value={username}
              onChange={txt => setUsername(txt.target.value)}
            />
            <TextField
              required
              id="outlined-required"
              label="Nome"
              value={name}
              onChange={txt => setName(txt.target.value)}
            />
            <TextField
              required
              id="outlined-required"
              label="Email"
              type="email"
              value={email}
              onChange={txt => setEmail(txt.target.value)}
            />
            <FormControl sx={{ m: 1, width: '50ch' }}>
              <InputLabel id="demo-multiple-name-label">Categoria</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                input={<OutlinedInput label="Categoria" />}
                MenuProps={MenuProps}
                value={category}
                onChange={txt => setCategory(txt.target.value)}
              >
                <MenuItem key="" value="">
                  Selecione uma categoria
                </MenuItem>
                {categories.map(c => (
                  <MenuItem key={c.categoryId} value={String(c.categoryId)}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              id="outlined-required"
              label="Jira ID"
              value={jiraId}
              onChange={txt => setjiraId(txt.target.value)}
            />
            {user && user.id && (
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{ textAlign: 'center' }}>
                    Status
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="status"
                    name="status"
                    defaultValue={false}
                    value={status}
                    onChange={event => setStatus(event.target.value === 'true')}
                  >
                    <FormControlLabel
                      value
                      control={<Radio color="primary" />}
                      label="Ativo"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label="Desativado"
                      labelPlacement="top"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            )}
          </div>
        </Box>
      </Grid>

      <Stack
        direction="row"
        justifyContent="space-evenly"
        justifyItems="center"
        margin={5}
      >
        <LoadingButton
          color="warning"
          loadingPosition="start"
          loading={loading}
          size="large"
          variant="contained"
          startIcon={<ArrowbackIcon />}
          onClick={() => navigate(-1)}
        >
          Voltar
        </LoadingButton>
        {user && user.id ? (
          <>
            <LoadingButton
              color="secondary"
              loadingPosition="start"
              loading={loading}
              size="large"
              variant="contained"
              startIcon={<PasswordIcon />}
              onClick={() => setOpenDialogReset(true)}
            >
              Resetar senha
            </LoadingButton>
            <LoadingButton
              loadingPosition="start"
              loading={loading}
              size="large"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={updateUser}
            >
              Editar
            </LoadingButton>
          </>
        ) : (
          <LoadingButton
            loadingPosition="start"
            loading={loading}
            size="large"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={saveUser}
          >
            Salvar
          </LoadingButton>
        )}
      </Stack>

      <AlertSnackbars
        message={messageAlert}
        type={typeMessage}
        open={openAlert}
        setOpen={setOpenAlert}
      />

      <Dialog onClose={() => setOpenDialogReset(false)} open={openDialogReset}>
        <DialogTitle>
          Você tem certeza de que deseja redefinir a senha deste usuário? Esta
          ação é irreversível.
        </DialogTitle>
        <DialogActions>
          <Button onClick={resetPassword}>Sim</Button>
          <Button onClick={() => setOpenDialogReset(false)} autoFocus>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default NewUser;
