import * as React from 'react';

import { Box, Button, TextField, Breadcrumbs, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

import api from '../../../services/api';

const NewBranch: React.FC = () => {
  const [descricao, setDescricao] = React.useState<string>('');
  const [regiao, setRegiao] = React.useState<string>('');
  const [saving, setSeving] = React.useState<boolean>(false);

  const saveSilo = async () => {
    setSeving(true);
    try {
      const data = {
        descricao,
        regiao,
        status: true,
      };
      await api.post('/branch', data);
      setSeving(false);
    } catch (error) {
      setSeving(false);
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
          Silos
        </Link>
        <Typography color="text.primary">Nova Filial</Typography>
      </Breadcrumbs>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '50ch' },
          bgcolor: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            required
            id="outlined-required outlined-number"
            label="Descrição"
            value={descricao}
            onChange={txt => setDescricao(txt.target.value)}
          />
          <TextField
            required
            id="outlined-required outlined-number"
            label="Região"
            value={regiao}
            onChange={txt => setRegiao(txt.target.value)}
          />
        </div>
        <div>{/* <TextField type="file" /> */}</div>
        <Stack direction="row" spacing={6} margin={10}>
          <LoadingButton
            loadingPosition="start"
            loading={saving}
            size="large"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={saveSilo}
          >
            Salvar
          </LoadingButton>
          <Button
            size="large"
            variant="contained"
            startIcon={<CancelIcon />}
            color="secondary"
          >
            Cancelar
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default NewBranch;
