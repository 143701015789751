import React from 'react';
import {
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import CardFirewood from './components/CardFirewood';
import { OrderServiceFirewoods } from '../../types';
import api from '../../services/api';
import useStorageUser from '../../hooks/useStorageUser';

const Firewoods: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [orderServices, setOrderServices] = React.useState<
    OrderServiceFirewoods[]
  >([]);

  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      api
        .get('/firewoods', {
          headers: { Authorization: `Barier ${userParser?.token}` },
        })
        .then(response => {
          setOrderServices(response.data);
          setLoading(false);
        })
        .catch(error => {
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
          setLoading(false);
        });
    }
  }, [userParser]);

  const removeOrderService = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.delete(`/firewood/${id}`, {
        headers: { Authorization: `Barier ${userParser?.token}` },
      });
      if (response.data && orderServices) {
        setOrderServices(os => os.filter(ve => ve.id !== id));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Typography color="text.primary">Lenha</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop={2}
      >
        {orderServices.map(os => (
          <CardFirewood data={os} key={os.id} deleteOS={removeOrderService} />
        ))}
      </Grid>
    </Container>
  );
};

export default Firewoods;
