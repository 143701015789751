import styled, { css } from 'styled-components';

interface PropsDropContainer {
  isDragAccept: boolean;
  isDragReject: boolean;
}
interface PropsUploadMessage {
  type?: string;
}
interface PropsPreview {
  src: string;
}

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 4px;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})<PropsDropContainer>`
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;

  transition: height 0.2s ease;

  ${props => props.isDragAccept && dragActive}
  ${props => props.isDragReject && dragReject}
`;

const messageColors: any = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5',
};

export const UploadMessage = styled.p<PropsUploadMessage>`
  display: flex;
  color: ${props => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

export const ContainerFiles = styled.ul`
  margin-top: 20px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;

    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }
  }
`;

export const Preview = styled.div<PropsPreview>`
  margin-left: -36px;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
