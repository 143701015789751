/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';

interface OptionsProps {
  open: boolean;
  title?: string | undefined;
  handleConfirm: () => void;
  handleClose: () => void;
}

const DialogDelete: React.FC<OptionsProps> = props => {
  const { open, title, handleClose, handleConfirm } = props;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {title || 'Você realmente deseja remover este item?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleConfirm}>Sim</Button>
        <Button onClick={handleClose} autoFocus>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDelete;
