import * as React from 'react';
import { Box, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

type RowWarehouse = {
  id: number;
  descricao: string;
  categoria: string;
  filial: string;
  region: string;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'descricao',
    headerName: 'Descrição',
    width: 200,
  },
  {
    field: 'categoria',
    headerName: 'Categoria',
    width: 200,
  },
  {
    field: 'filial',
    headerName: 'Filial',
    width: 250,
  },
  {
    field: 'region',
    headerName: 'Região',
    width: 160,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const navigate = useNavigate();
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => navigate(`/silo/update/${id}`)}
          color="inherit"
        />,
      ];
    },
  },
];

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);

const TableSilo: React.FC<{ rows: RowWarehouse[]; loading: boolean }> = ({
  rows,
  loading,
}) => (
  <Box sx={{ height: 520, width: '100%' }} component={Paper} marginTop={2}>
    <DataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      slots={{
        toolbar: CustomToolbar,
      }}
    />
  </Box>
);

export default TableSilo;
