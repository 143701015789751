import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import { GeometricShape as GeometricShapeType } from '../../types';
import CardGeometricShape from '../../components/CardGeometricShape';
import useStorageUser from '../../hooks/useStorageUser';

const GeometricShape: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);

  const [orderServices, setOrderServices] =
    React.useState<GeometricShapeType[]>();

  const params = useParams();
  const navigate = useNavigate();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      const uri = params.id
        ? `/geometric_shapes_branch_product?cBranchId=${params.id}`
        : '/geometric_shapes';
      api
        .get(uri, {
          headers: { Authorization: `Barier ${userParser?.token}` },
        })
        .then(response => {
          setOrderServices(response.data);
          setLoading(false);
        })
        .catch(error => {
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            navigate('/auth/login');
          }
          setLoading(false);
        });
    }
  }, [params, userParser]);

  const removeGeometricShape = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.delete(`/geometric_shape/${id}`, {
        headers: { Authorization: `Barier ${userParser?.token}` },
      });
      if (response.data && orderServices) {
        if (orderServices.length === 1) {
          setOrderServices([]);
        } else {
          const veAux: GeometricShapeType[] = orderServices.slice();
          setOrderServices(veAux.filter(ve => ve.id !== id));
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Typography color="text.primary">Formas Gemétricas</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop={2}
      >
        {orderServices &&
          orderServices.map(os => (
            <CardGeometricShape
              data={os}
              key={os.id}
              removeGeometricShape={removeGeometricShape}
            />
          ))}
      </Grid>
    </Container>
  );
};

export default GeometricShape;
