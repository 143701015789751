import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Grid,
  TableBody,
  Typography,
} from '@mui/material';
import { LoteHeightFirewood } from '../../../../types';

type HeightsProps = {
  heights: Array<LoteHeightFirewood>;
  initialWidth: number;
  finalWidth: number;
  lengthLeft: number;
  lengthRight: number;
  lote: number;
  // eslint-disable-next-line no-unused-vars
  getResult: (result: number) => void;
};

const TableLote: React.FC<HeightsProps> = ({
  heights,
  initialWidth,
  finalWidth,
  lengthLeft,
  lengthRight,
  lote,
  getResult,
}) => {
  const totalLeft = heights
    ? heights.reduce((acc, act) => acc + Number(act.left), 0)
    : 0;
  const totalRight = heights
    ? heights.reduce((acc, act) => acc + Number(act.right), 0)
    : 0;
  const lengthHeight = heights ? heights.length : 1;

  const averageHeight =
    totalRight > 0 && totalLeft > 0
      ? (totalRight / lengthHeight + totalLeft / lengthHeight) / 2
      : 0;

  const averageWidth = (initialWidth + finalWidth) / 2;
  const averageLenth = (lengthLeft + lengthRight) / 2;
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setTotal(averageHeight * averageLenth * averageWidth);
    getResult(averageHeight * averageLenth * averageWidth);
  }, []);

  return (
    <Grid
      container
      style={{
        backgroundColor: '#dcdcdc',
        border: 1,
        borderRadius: 5,
        padding: 8,
      }}
    >
      <Grid item lg={12}>
        <Typography style={{ fontWeight: 'bold' }}>Lote: {lote}</Typography>
      </Grid>
      <Grid item lg={6}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4} align="center">
                  Altura
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Direito
                </TableCell>
                <TableCell colSpan={2} align="center">
                  Esquerdo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {heights.map((height, index) => (
                <TableRow key={height.id}>
                  <TableCell align="center">{1 + index}</TableCell>
                  <TableCell align="center">{height.right}</TableCell>
                  <TableCell colSpan={2} align="center">
                    {height.left}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Média</TableCell>
                <TableCell>
                  {totalRight > 0 ? (totalRight / lengthHeight).toFixed(2) : 0}
                </TableCell>
                <TableCell>Média</TableCell>
                <TableCell>
                  {totalLeft > 0 ? (totalLeft / lengthHeight).toFixed(2) : 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {averageHeight.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} style={{ paddingLeft: 8 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  Comprimento
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Direito</TableCell>
                <TableCell align="center">Esquerdo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{lengthRight}</TableCell>
                <TableCell align="center">{lengthLeft}</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="center">Média</TableCell>
                <TableCell align="center">{averageLenth}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} style={{ marginTop: 6 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  Largura
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Inicial</TableCell>
                <TableCell align="center">Final</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{initialWidth}</TableCell>
                <TableCell align="center">{finalWidth}</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="center">Média</TableCell>
                <TableCell align="center">{averageWidth}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} style={{ marginTop: 6 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{total.toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TableLote;
