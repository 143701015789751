import React from 'react';

import {
  Box,
  Breadcrumbs,
  Container,
  Fab,
  LinearProgress,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import { WareHouse } from '../../interfaces';
import api from '../../services/api';
import TableSilo from './components/TableSilo';
import useStorageUser from '../../hooks/useStorageUser';

const Silos: React.FC = () => {
  const [warehouses, setWarehouses] = React.useState<WareHouse[]>([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { userParser, validateUserStorage, clearUserStorage } =
    useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser && userParser.token) {
      if (userParser.lCategory.name === 'gbs') {
        navigate('/report');
      }
      setLoading(true);
      const url = params.id ? `/silos/branches/${params.id}` : '/panel_silos';
      api
        .get(url, { headers: { Authorization: `Barier ${userParser.token}` } })
        .then(response => {
          setWarehouses(response.data);
          setLoading(false);
        })
        .catch(error => {
          const err = error as AxiosError;
          if (err.response?.status === 401) {
            setLoading(false);
            clearUserStorage();
          }
        });
    }
  }, [userParser]);

  return (
    <Container>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Typography color="text.primary">Silos | Armazéns</Typography>
      </Breadcrumbs>
      {warehouses && (
        <TableSilo
          rows={warehouses.map(d => ({
            id: d.id,
            descricao: d.descricao,
            categoria: d.categoria.description,
            filial: d.branch.descricao,
            region: d.branch.regiao,
          }))}
          loading={loading}
        />
      )}
      <Fab
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        color="primary"
        aria-label="add"
        onClick={() => navigate('/silo/new')}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
};

export default Silos;
