import {
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Box,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from '@mui/material';
import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';

import { OrderServiceVEProps } from '../../interfaces';
import api from '../../services/api';
import ImgSignatures from '../../components/ImgSignatures';
import useStorageUser from '../../hooks/useStorageUser';

const VolumesExtras: React.FC = () => {
  const [order, setOrder] = React.useState<OrderServiceVEProps | null>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const params = useParams();
  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (userParser) {
      setLoading(true);
      api
        .get(`/extra_volume/${params.id}`, {
          headers: { Authorization: `Barier ${userParser.token}` },
        })
        .then(response => {
          const { data } = response;
          setOrder(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [params, userParser]);

  const removeExtraVolume = async (id: number | undefined): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.delete(`/extra_volume/item/${id}`, {
        headers: { Authorization: `Barier ${userParser?.token}` },
      });
      if (response.data) {
        setOrder(value => {
          if (value && value.extraVolumes) {
            return {
              ...value,
              extraVolumes: value.extraVolumes.filter(ev => ev.id !== id),
            };
          }
          return null;
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/branches"
        >
          Filiais
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to="/extra_volumes"
        >
          Ordens de Serviços
        </Link>
        <Typography color="text.primary">Volumes extras</Typography>
      </Breadcrumbs>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table stickyHeader size="small" aria-label="teste">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Volumes extras
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order &&
              order.extraVolumes?.map(ve => (
                <TableRow key={ve.id}>
                  <TableCell component="td" scope="row">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      style={{ marginRight: 20 }}
                      onClick={() => removeExtraVolume(ve.id)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    {ve.tipo} - {ve.descricao}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {order.produto.descricao}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3,
                    }).format(ve.quantidade)}
                    {` `}
                    {order.produto.unidade}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} md={12} lg={12}>
        <ImgSignatures data={order ? order.filesVE ?? [] : []} />
      </Grid>
    </Container>
  );
};

export default VolumesExtras;
