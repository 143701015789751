/* eslint-disable no-unused-vars */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import * as React from 'react';

interface VolumesProps {
  capacityBox: number;
  box: number;
  boxSelected: number;
  title: string;
}

const BoxVolumes: React.FC<VolumesProps> = ({
  box,
  capacityBox,
  boxSelected,
  title,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Capacidade total</TableCell>
            <TableCell align="center">Quantidade de caixa</TableCell>
            <TableCell align="center">Volume por caixa</TableCell>
            <TableCell align="center">
              Quantidade de caixa cheia {title}
            </TableCell>
            <TableCell align="center">Volume {title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 3,
              }).format(capacityBox)}
            </TableCell>
            <TableCell align="center">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 3,
              }).format(box)}
            </TableCell>
            <TableCell align="center">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 3,
              }).format(capacityBox / box)}
            </TableCell>
            <TableCell align="center">{boxSelected}</TableCell>
            <TableCell align="center">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 3,
              }).format((capacityBox / box) * boxSelected)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BoxVolumes;
