import * as React from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  LinearProgress,
  Typography,
  Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import DetailIcon from '@mui/icons-material/RemoveRedEyeSharp';

import AlertSaveError from '../../../components/CustomizedSnackbarsError';
import FilterReport from './components/FilterReport';
import api from '../../../services/api';
import { Branch } from '../../../interfaces';
import ToolbarExportToCSV from '../../../components/ToolbarExportToCSV';
import useStorageUser from '../../../hooks/useStorageUser';

type RowWarehouse = {
  branch: Branch;
  totalAccount: Number;
  otherThirdPartyBranches: Number;
  thirdPartyStock: Number;
  reference: string;
  createdTime: Date;
  physicalStock: Number;
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'filial',
    headerName: 'Filial',
    valueGetter: params => params.row?.branch.descricao,
    width: 250,
  },
  {
    field: 'region',
    headerName: 'Região',
    valueGetter: params => params.row?.branch.regiao,
    width: 100,
  },
  {
    field: 'createdTime',
    headerName: 'Data do resumo',
    valueFormatter: ({ value }) =>
      Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }).format(new Date(value)),
    width: 150,
  },
  {
    field: 'physicalStock',
    headerName: 'Estoque Físico (kg)',
    valueFormatter: ({ value }) =>
      Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
  },
  {
    field: 'totalAccount',
    headerName: 'Total contabil (kg)',
    valueFormatter: ({ value }) =>
      Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    width: 150,
  },
  {
    field: 'otherThirdPartyBranches',
    headerName: 'Outras Filias e/ou Terceiros (kg)',
    valueFormatter: ({ value }) =>
      Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    width: 200,
  },
  {
    field: 'thirdPartyStock',
    headerName: 'Estoque em poder de terceiros (kg)',
    valueFormatter: ({ value }) =>
      Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    width: 200,
  },
  {
    field: 'total',
    headerName: 'Total (kg)',
    valueFormatter: ({ value }) =>
      Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      return [
        <GridActionsCellItem
          icon={<DetailIcon />}
          label="Detail"
          className="textPrimary"
          onClick={() => window.open(`/report/resume/${id}`)}
          color="inherit"
        />,
      ];
    },
  },
];

const Consolidated: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<String>('');
  const [month, setMonth] = React.useState<String>('');
  const [year, setYear] = React.useState<String>('');
  const [dataReport, setDataReport] = React.useState<RowWarehouse[]>([]);
  const [openAlertError, setOpenAlertError] = React.useState<boolean>(false);
  const [messageAlertErro, setmessageAlertErro] = React.useState<string>('');

  const { userParser, validateUserStorage } = useStorageUser();
  React.useEffect(() => validateUserStorage(), [userParser]);

  React.useEffect(() => {
    if (product !== '' && month !== '' && year !== '' && userParser) {
      setLoading(true);
      api
        .get(
          `/report/consolidated?product=${product}&reference=${year}-${month}`,
          {
            headers: { Authorization: `Barier ${userParser.token}` },
          },
        )
        .then(response => {
          setDataReport(response.data);
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          setOpenAlertError(true);
          setmessageAlertErro(e.message);
          setLoading(false);
        });
    }
  }, [product, month, year, userParser]);

  return (
    <Container maxWidth="lg">
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/report">
          Relatórios
        </Link>
        <Typography color="text.primary">Consolidado</Typography>
      </Breadcrumbs>

      <FilterReport
        product={product}
        handleProduct={setProduct}
        month={month}
        handlerMonth={setMonth}
        year={year}
        handlerYear={setYear}
      />

      <Box sx={{ height: 520, width: '100%' }} component={Paper} marginTop={2}>
        <DataGrid
          columns={columns}
          rows={dataReport}
          loading={loading}
          disableRowSelectionOnClick
          pageSizeOptions={[5, 10, 20, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: () => ToolbarExportToCSV({ name: 'report-consolidated' }),
          }}
        />
      </Box>
      <AlertSaveError
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={messageAlertErro}
      />
    </Container>
  );
};

export default Consolidated;
