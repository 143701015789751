import * as React from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import AlertSaveError from '../../components/CustomizedSnackbarsError';
import AlertSuccess from '../../components/CustomizedSnackbars';
import CustomModal from '../../components/CustomModal';
import imageLogin from '../../images/auth.jpg';

import api from '../../services/api';
import useStorageUser from '../../hooks/useStorageUser';

const classes = {
  main: {
    flex: 1,
    padding: 10,
  },
  textInput: {
    borderRadius: 4,
    paddingLeft: 8,
    paddingHorizontal: 2,
    paddingVertical: 2,
    fontSize: 12,
    borderColor: '#474242',
    borderWidth: 0.5,
    backgroundColor: '#fff',
    width: 300,
    margin: 8,
  },
  button: {
    marginTop: 16,
    borderRadius: 4,
    padding: 8,
    backgroundColor: '#01a699',
    alignItems: 'center',
    width: 290,
    marginLeft: 10,
  },
};

const Login: React.FC = () => {
  const [user, setUser] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [openAlertError, setOpenAlertError] = React.useState<boolean>(false);
  const [messageAlertErro, setmessageAlertErro] = React.useState<string>('');

  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [messageSuccess, setmessageSuccess] = React.useState<string>('');
  const [isResetPassword, setResetPassword] = React.useState<boolean>(false);

  const [tokenTemp, setTokenTemp] = React.useState<string>('');
  const [isNewPassword, setNewPassword] = React.useState<boolean>(false);

  const { userParser, setUserStorage } = useStorageUser();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!userParser) {
      const userStorage = localStorage.getItem('@storage_user');
      if (userStorage) setUserStorage(userStorage);
    }
    if (userParser) {
      navigate('/');
    }
  }, [userParser]);

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);
    if (user === '' || password === '') {
      setLoading(false);
      setmessageAlertErro('Digite o usuário e senha!');
      setOpenAlertError(true);
      return;
    }
    try {
      const response = await api.post('/auth/login', {
        username: user,
        password,
      });
      const jsonUser = JSON.stringify(response.data);
      localStorage.setItem('@storage_user', jsonUser);
      setUserStorage(jsonUser);
      navigate('/');
      setLoading(false);
    } catch (error) {
      const err = error as AxiosError;
      setLoading(false);
      setmessageAlertErro(err.response?.data.message ?? err.message);
      setOpenAlertError(true);

      if (err.response?.status === 400) {
        setPassword('');
        setTokenTemp(err.response.data.token);
        setNewPassword(true);
      }
    }
  };

  const handleNewPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);
    if (user === '' || password === '') {
      setLoading(false);
      setmessageAlertErro('Digite o usuário e senha!');
      setOpenAlertError(true);
      return;
    }
    try {
      const response = await api.put(
        `/auth/change_password/${user}`,
        {
          password,
        },
        {
          headers: { Authorization: `Barier ${tokenTemp}` },
        },
      );
      setmessageSuccess(response.data.message);
      setOpenSuccess(true);
      setNewPassword(false);
      setLoading(false);
    } catch (error) {
      const err = error as AxiosError;
      setLoading(false);
      setmessageAlertErro(err.response?.data.message ?? err.message);
      setOpenAlertError(true);
    }
  };

  const handleResetPasssword = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);

    try {
      const response = await api.put(`/auth/reset/${user}`);
      setmessageSuccess(response.data.message);
      setOpenSuccess(true);
      setLoading(false);
      setResetPassword(false);
    } catch (error) {
      const err = error as AxiosError;
      setLoading(false);
      setmessageAlertErro(err.response?.data.message ?? err.message);
      setOpenAlertError(true);
    }
  };

  return (
    <div style={classes.main}>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <img
          src={`${imageLogin}?size=338&ext=jpg`}
          srcSet={`${imageLogin}?size=338&ext=jpg`}
          alt="login"
          loading="lazy"
        />
        <FormControl component="form" onSubmit={handleSignIn}>
          <TextField
            style={classes.textInput}
            required
            id="outlined-required outlined-number"
            placeholder="Usuário"
            value={user}
            onChange={txt => setUser(txt.target.value)}
          />

          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              style={classes.textInput}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              required
              value={password}
              onChange={txt => setPassword(txt.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(show => !show)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={classes.button}
            type="submit"
          >
            {loading ? (
              <CircularProgress size={28} color="inherit" />
            ) : (
              'Entrar'
            )}
          </Button>
        </FormControl>
      </Grid>
      <AlertSaveError
        open={openAlertError}
        setOpen={setOpenAlertError}
        message={messageAlertErro}
      />

      <AlertSuccess
        message={messageSuccess}
        open={openSuccess}
        setOpen={setOpenSuccess}
      />

      <CustomModal
        open={isResetPassword}
        setOpen={setResetPassword}
        widht={400}
      >
        <Grid justifyItems="center" display="flex" flexDirection="column">
          <h2 id="modal-modal-title" style={{ textAlign: 'center' }}>
            Resetar senha
          </h2>
          <FormControl component="form" onSubmit={handleResetPasssword}>
            <TextField
              style={classes.textInput}
              required
              id="outlined-required outlined-number"
              placeholder="Usuário"
              value={user}
              onChange={txt => setUser(txt.target.value)}
            />
            <Grid
              justifyContent="center"
              alignItems="center"
              justifyItems="center"
              alignContent="center"
              display="flex"
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={classes.button}
                type="submit"
              >
                {loading ? (
                  <CircularProgress size={28} color="inherit" />
                ) : (
                  'Enviar'
                )}
              </Button>
              <Button
                size="large"
                variant="contained"
                color="warning"
                style={classes.button}
                onClick={() => setResetPassword(false)}
              >
                Cancelar
              </Button>
            </Grid>
          </FormControl>
        </Grid>
      </CustomModal>

      <CustomModal open={isNewPassword} setOpen={setNewPassword} widht={400}>
        <h2 id="modal-modal-title" style={{ textAlign: 'center' }}>
          Alterar senha
        </h2>
        <b>
          Para garantir a segurança da sua conta, siga estas diretrizes ao
          definir sua senha:
        </b>
        <ul id="modal-modal-description" style={{ fontSize: 9 }}>
          <li>
            Comprimento Mínimo: Sua senha deve ter no mínimo 16 caracteres.
          </li>
          <li>Utilize uma combinação de: Letras maiúsculas e minúsculas</li>
          <li>Caracteres especiais (!@#$%ˆ&*?)</li>
          <li>Números (0-9)</li>
          <li>Evite Sequências e Padrões (ex.: `12345`, `abcdef`)</li>
        </ul>
        <FormControl component="form" onSubmit={handleNewPassword}>
          <FormControl sx={{ m: 0, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              style={classes.textInput}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              required
              value={password}
              onChange={txt => setPassword(txt.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(show => !show)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={classes.button}
            type="submit"
          >
            {loading ? (
              <CircularProgress size={28} color="inherit" />
            ) : (
              'Enviar'
            )}
          </Button>
        </FormControl>
      </CustomModal>
    </div>
  );
};
export default Login;
