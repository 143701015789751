import * as React from 'react';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableFooter,
} from '@mui/material';
import CardTotalV from '../../../../components/CardTotalV';
import {
  VAppointment,
  FlatcalcBunker,
  VApontamento,
} from '../../../../interfaces';

type TankProps = {
  orderService: VAppointment;
};

type BunkerProps = {
  flatcalc: FlatcalcBunker;
};

type AppintmentProps = {
  title: string;
  vApontamentos: Array<VApontamento>;
};

const TableAppointment: React.FC<AppintmentProps> = ({
  title,
  vApontamentos,
}) => (
  <Grid item xs={12} md={6} lg={4}>
    <TableContainer component={Paper}>
      <Table size="small" aria-label={title}>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vApontamentos.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                L{index + 1}
              </TableCell>
              <TableCell align="center">
                {Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(Number(row.medidaBCDCentral))}{' '}
                m
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Média</TableCell>
            <TableCell align="center">
              {Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 3,
              }).format(
                vApontamentos.reduce(
                  (acc, act) => acc + Number(act.medidaBCDCentral),
                  0,
                ) / vApontamentos.length,
              )}{' '}
              m
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </Grid>
);

const TableBunker: React.FC<BunkerProps> = ({ flatcalc }) => (
  <TableContainer component={Paper} sx={{ marginLeft: 1, marginTop: 2 }}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Diametro</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.diameter))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Altura</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.height))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Volume (m³)</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.volumeCalculation))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Altura da parede</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.wallHeight))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Volume do Cilindro</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.cylinderVolume))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">Volume (ton)</TableCell>
          <TableCell align="left">
            {Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(Number(flatcalc.totalTon))}
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  </TableContainer>
);

const Bunker: React.FC<TankProps> = ({ orderService }) => {
  return (
    <>
      <Grid container direction="row" spacing={1} marginLeft={3} marginTop={2}>
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Diâmetro"
          value={Number(orderService.silo.larguraA)}
        />
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Altura"
          value={Number(orderService.silo.alturaCoberturaB)}
        />
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Altura da Parede"
          value={Number(orderService.silo.alturaParedeC)}
        />

        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Densidade"
          value={orderService.densidadeColetada}
        />
        <CardTotalV
          isAppointment={false}
          widthLG={2}
          title="Fator de compactação"
          value={orderService.fatorCompactacaoColetada}
        />
        <CardTotalV
          isAppointment
          widthLG={2}
          title="Altura"
          value={
            orderService.vApontamentos
              ? orderService.vApontamentos[0].medidaCDDireito
              : 0
          }
        />
      </Grid>
      <Grid container direction="row" spacing={1} marginLeft={3} marginTop={2}>
        {orderService.vApontamentos && (
          <TableAppointment
            title="Média da distância entre a parede e massa de grão"
            vApontamentos={orderService.vApontamentos.filter(
              value => value.oitao <= 5,
            )}
          />
        )}
        {orderService.vApontamentos && (
          <TableAppointment
            title="Média da altura da parede até o produto"
            vApontamentos={orderService.vApontamentos.filter(
              value => value.oitao > 5,
            )}
          />
        )}
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={4}>
        {orderService.flatcalc && (
          <TableBunker flatcalc={orderService.flatcalc as FlatcalcBunker} />
        )}
      </Grid>
    </>
  );
};

export default Bunker;
