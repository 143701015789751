import React from 'react';
import { styled, Toolbar, Divider } from '@mui/material';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems, SecondaryListItems } from './MenuItem';
import { UserStorageInterface } from '../../../interfaces';

interface MyDrawerProps extends MuiDrawerProps {
  open?: boolean;
}

interface DrawerProps {
  open: boolean;
  toggleDrawer: any;
  userParser: UserStorageInterface;
}

const MyDrawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})<MyDrawerProps>(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Drawer: React.FC<DrawerProps> = ({ open, toggleDrawer, userParser }) => {
  return (
    <MyDrawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <MainListItems user={userParser} />
      <Divider />
      <SecondaryListItems user={userParser} />
    </MyDrawer>
  );
};

export default Drawer;
